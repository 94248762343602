import {formatEther} from "@ethersproject/units";
import {StakeRecord} from "../hooks/useStakeRecords";
import {StakeRewardRecord} from "../hooks/useStakeRewardRecords";
import {BigNumber} from "@ethersproject/bignumber";
import {CLAIM_HUNTERS_V3_ADDRESS, CLAIM_V3_ADDRESS, STAKE_HUNTERS_V3_ADDRESS, STAKE_V2_ADDRESS, STAKE_V3_ADDRESS} from "../constants/addresses";
import {Contract} from '@ethersproject/contracts'


export function calculateStakeReturns(
  amount?: string,
  months?: number,
  stakeBonus?: number | undefined,
  stakeLongBonus?: number | undefined
) : {
  bonus: number
  longBonus: number
  APY: number
  total: number
  bonusPercent: number
  longBonusPercent: number
} {
    if (!stakeBonus){
        stakeBonus = 150
    }
    if (!stakeLongBonus){
        stakeLongBonus = 300
    }

    let [bonus, longBonus, APY ,total ,bonusPercent,longBonusPercent] = [0, 0, 0, 0, 0, 0];
    const amountNumber = parseFloat(amount ?? "0") ?? 0;
    months = months ?? 0;

    if (months && amountNumber){
      bonus = amountNumber * months * 30 * stakeBonus / 365 / 1000;
      longBonus = amountNumber * months * 30 * months * 30 / 1000 * stakeLongBonus / 365 / 1000;
      APY = (bonus + longBonus) / months * 12 / amountNumber * 100;
      total = bonus + longBonus;
      bonusPercent = bonus / total * 100;
      longBonusPercent = longBonus / total * 100;
    }

  return {bonus, longBonus, APY, total, bonusPercent, longBonusPercent}
}

export function calculateStakeStats(stakeInfo: StakeRecord[]) {
    let lastDayReward = 0;
    for (let i = 0; i < stakeInfo.length; i++) {
        const nowTimestamp = +new Date() / 1000;
        const endTimestamp  = stakeInfo[i].lockTimestamp + stakeInfo[i].lockDays * 86400;
        const { total } = calculateStakeReturns(formatEther(stakeInfo[i].amount), stakeInfo[i].lockDays/30,
            stakeInfo[i].shareBonus, stakeInfo[i].shareLongBonus);
        const dailyBonus = total / stakeInfo[i].lockDays
        if (endTimestamp > nowTimestamp  && stakeInfo[i].lockTimestamp < nowTimestamp){
            lastDayReward += dailyBonus
        }
    }
    return { lastDayReward }
}

export function calculateLastMonthProfit(stakeInfo: StakeRecord[]) {
    let lastMonthProfitRecords = [];
    for (let i = 0; i < stakeInfo.length; i++) {

        const nowTimestamp = +new Date() / 1000;
        const monthAfterStart = stakeInfo[i].lockTimestamp + 30 * 86400;
        const passedTimestamp = nowTimestamp - stakeInfo[i].lockTimestamp;
        const passedMonthsCount = Math.floor(passedTimestamp/86400/30);
        const claimTimestamp = stakeInfo[i].lockTimestamp + passedMonthsCount * 30 * 86400;
        const endTimestamp  = stakeInfo[i].lockTimestamp + stakeInfo[i].lockDays * 86400 + 30 * 86400;
        const endDate = new Date(endTimestamp*1000);
        const claimDate = new Date(claimTimestamp*1000);
        const { total, APY } = calculateStakeReturns(formatEther(stakeInfo[i].amount), stakeInfo[i].lockDays/30,
            stakeInfo[i].shareBonus, stakeInfo[i].shareLongBonus);
        const monthlyBonus = total / (stakeInfo[i].lockDays / 30);

        if (endTimestamp > nowTimestamp  && monthAfterStart < nowTimestamp){
            lastMonthProfitRecords.push({monthlyBonus: monthlyBonus,
                total: total,
                APY: APY,
                staked: stakeInfo[i].amount,
                claimableDate: stakeInfo[i].version === "1" ? endDate : claimDate });
        }
    }
    return lastMonthProfitRecords
}

export function calculateStakeRewardStats(stakeRewardsInfo: StakeRewardRecord[]) {
    let currentReward = stakeRewardsInfo.reduce((partialSum, a) => partialSum.add(a.amount), BigNumber.from(0));
    return { currentReward: currentReward }
}

export function getStakeVersion(contract: Contract, chainId: any){
    let version = "1";
    if (contract.address == (chainId ? STAKE_V2_ADDRESS[chainId] : undefined)){
        version = "2";
    }
    if (contract.address == (chainId ? STAKE_V3_ADDRESS[chainId] : undefined) || contract.address == (chainId ? STAKE_HUNTERS_V3_ADDRESS[chainId] : undefined)){
        version = "3";
    }
    return version
}

export function getClaimVersion(contract: Contract, chainId: any){
    let version = "2";
    if (contract.address == (chainId ? CLAIM_V3_ADDRESS[chainId] : undefined) || contract.address == (chainId ? CLAIM_HUNTERS_V3_ADDRESS[chainId] : undefined)){
        version = "3";
    }
    return version
}