export const getApiConfig = (auth = true) => {
    let config = {
        baseURL: process.env.REACT_APP_API_URL,
        headers: {}
    };
    if (auth === true) {
        config["headers"] = {
            'authorization': 'Bearer ' + localStorage.getItem("accessToken")
        }
    }
    return config;
}