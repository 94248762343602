// @ts-nocheck
import React, {useState, useEffect} from 'react';
import styles from "./Staking.module.sass";
import cn from "classnames";
import {useWeb3React} from "@web3-react/core";
import {useTotalStaked} from "../../hooks/useTotalStaked";
import {StakeToken, useStakeRecords} from "../../hooks/useStakeRecords";
import {useStakerRewardPending} from "../../hooks/useStakerRewardPending";
import {calculateStakeRewardStats, calculateStakeStats} from "../../functions/stake";
import Tabs from "./Tabs"
import {useClaimContract} from "../../hooks/useContract";
import {useStakeRewardRecords} from "../../hooks/useStakeRewardRecords";
import {formatEther} from "@ethersproject/units";
import {CLAIM_HUNTERS_V3_ADDRESS, CLAIM_V2_ADDRESS, CLAIM_V3_ADDRESS} from "../../constants/addresses";
import Title from "../../components/Title";
import { NavLink } from "react-router-dom";
import ReactTooltip from 'react-tooltip';

const StakingMain = (props:any) => {
    const stakeToken = props?.stakeToken;
    const { account, active } = useWeb3React();
    const stakeRecords = useStakeRecords(account, stakeToken);
    const [mhuntPrice, setMhuntPrice] = useState();
    const { lastDayReward } = calculateStakeStats(stakeRecords);
    const stakeRewardRecords = useStakeRewardRecords(account, stakeToken);
    const { currentReward } = calculateStakeRewardStats(stakeRewardRecords);
    const { totalShares, totalStaked } = useTotalStaked(account, stakeToken);
    const claimV2Contract = useClaimContract(CLAIM_V2_ADDRESS);
    const claimV3Contract = useClaimContract(CLAIM_V3_ADDRESS);
    const claimHuntersV3Contract = useClaimContract(CLAIM_HUNTERS_V3_ADDRESS);
    const [stakerRewardPendingV2, setStakerRewardPendingV2] = useState("0");
    const [stakerRewardPendingV3, setStakerRewardPendingV3] = useState("0");
    const [stakerHuntersRewardPendingV3, setStakerHuntersRewardPendingV3] = useState("0");
    let initialStakerRewardPendingV2 = useStakerRewardPending(claimV2Contract, account);
    let initialStakerRewardPendingV3 = useStakerRewardPending(claimV3Contract, account);
    let initialStakerHunterRewardPendingV3 = useStakerRewardPending(claimHuntersV3Contract, account);

    let totalPendingReward = (+formatEther(stakerRewardPendingV2)) + (+formatEther(stakerRewardPendingV3));
    if (stakeToken === StakeToken.HUNTERS){
        totalPendingReward = +formatEther(stakerHuntersRewardPendingV3)
    }

    useEffect(() => {
        setStakerRewardPendingV2(initialStakerRewardPendingV2);
        setStakerRewardPendingV3(initialStakerRewardPendingV3);
        setStakerHuntersRewardPendingV3(initialStakerHunterRewardPendingV3);
    }, [initialStakerRewardPendingV2, initialStakerRewardPendingV3, initialStakerHunterRewardPendingV3]);


    const getMhuntPrice = () => {
        var requestOptions = { method: 'GET', redirect: 'follow' };
        fetch("https://api.coingecko.com/api/v3/simple/price?ids=MetaShooter&vs_currencies=usd", requestOptions)
          .then(response => response.json())
          .then(data => {
            setMhuntPrice(data.metashooter.usd);
          })
          .catch(error => console.log('error getting mhunt price', error));
      };

    const claimRewards = () => {
        if (claimV2Contract && account && stakerRewardPendingV2 != "0"){
            claimV2Contract.claim(account)
                .then((value: any) => {
                    setStakerRewardPendingV2("0")
                    claimV3();
                })
                .catch((e: any) => {
                    console.log("Error on claiming rewards V2", e)
                })
            return
        }
        claimV3();
    };

    const claimV3  = () => {
        if (claimV3Contract && account && stakerRewardPendingV3 != "0"){
            claimV3Contract.claim(account)
                .then((value: any) => {
                    setStakerRewardPendingV3("0")
                })
                .catch((e: any) => {
                    console.log("Error on claiming rewards V3", e)
                })
        }
    }

    const claimHuntersV3  = () => {
        if (claimHuntersV3Contract && account && stakerHuntersRewardPendingV3 != "0"){
            claimHuntersV3Contract.claim(account)
                .then((value: any) => {
                    setStakerHuntersRewardPendingV3("0")
                })
                .catch((e: any) => {
                    console.log("Error on claiming hunters rewards V3", e)
                })
        }
    }
    useEffect(() => {
        getMhuntPrice();    
    }, []);
    
    return (<div className={styles.section}>
                <div className={cn(styles.statsSection)}>
                <Title>Your {stakeToken} stakings</Title>
                <p className={styles.tokenSelect}>Select token</p>
                <div className={styles.tokenSelectLinks}><NavLink activeClassName={cn(styles.linkActive)} to="/my-stakes">$MHUNT</NavLink><NavLink activeClassName={cn(styles.linkActive)} to="/my-hunters-stakes">$HUNTERS</NavLink></div>
                    <div className={cn(styles.statsContainer)}>
                        <div className={cn(styles.colum)}>
                            <p>Currently staked</p>
                            <div className={cn(styles.price)}>
                                <h5 className={cn(styles.whitefont)}>{+(totalStaked).toFixed(3)} {stakeToken}{totalStaked && stakeToken !== StakeToken.HUNTERS ? <span className={styles.priceInUSD}>/$ {(totalStaked * mhuntPrice).toFixed(3)}</span> : null}</h5>
                            </div>
                        </div>
                        <div data-tip={"Staked value + estimated rewards for staking period."} className={cn(styles.colum)}>
                            <p>Est. total value</p>
                            <div className={cn(styles.price)}>
                                <h5 className={cn(styles.whitefont)}>{+(totalShares).toFixed(3)} {stakeToken}{totalShares && stakeToken !== StakeToken.HUNTERS ? <span className={styles.priceInUSD}>/$ {(totalShares * mhuntPrice).toFixed(3)}</span> : null}</h5>
                            </div>
                        </div>
                        <div className={cn(styles.colum)}>
                            <p>Last day profit</p>
                            <div className={cn(styles.price)}>
                                <h5 className={cn(styles.greenfont)}>{+(lastDayReward).toFixed(3)} {stakeToken}{lastDayReward && stakeToken !== StakeToken.HUNTERS ? <span className={styles.priceInUSD}>/$ {(lastDayReward * mhuntPrice).toFixed(3)}</span> : null}</h5>
                            </div>
                        </div>
                        <div className={cn(styles.colum)}>
                            <p>Total claimed rewards</p>
                            <div className={cn(styles.price)}>
                                <h5 className={cn(styles.greenfont)}>{(+formatEther(currentReward)).toFixed(3)} {stakeToken}{currentReward && stakeToken !== StakeToken.HUNTERS ? <span className={styles.priceInUSD}>/$ {((+formatEther(currentReward)) * mhuntPrice).toFixed(3)}</span> : null}</h5>
                            </div>
                        </div>
                    </div>
                </div>
                { (totalPendingReward > 0) ?
                    <button
                        className={styles.claimRewards} style={{cursor:"pointer"}}
                        data-tip={`You can claim ${totalPendingReward.toFixed(3)} ${stakeToken}`}
                        onClick={() => stakeToken === StakeToken.HUNTERS ?  claimHuntersV3() : claimRewards() }
                        >Claim rewards
                        <ReactTooltip place={"top"} />
                    </button>
                :
                    <button
                        className={styles.claimRewardsNot}
                        data-tip={"You have no rewards to be claimed."}
                        >Already Claimed
                        <ReactTooltip place={"top"} />
                    </button>
                }

                <Tabs stakeToken={stakeToken}/>
            </div>);
};

export default StakingMain;