import React, { useState } from "react";
import cn from "classnames";
import styles from "./PurchaseTokens.module.sass";
import Image from "../Image"

const PurchaseTokens = () => {

const tokens = [
  {
    title: "Ascendex",
    image: "images/exchanges/ascendex.png",
    link: "https://ascendex.com/en/basic/cashtrade-spottrading/usdt/mhunt",
    width: "80px",
    height: "",
  },
  {
    title: "MEXC",
    image: "images/exchanges/mexo.png",
    link: "https://www.mexc.com/exchange/MHUNT_USDT",
    width: "70px",
    height: "",
  },
  {
    title: "Gate.io",
    image: "images/exchanges/gateio.png",
    link: "https://www.gate.io/trade/MHUNT_USDT",
    width: "80px",
    height: "",
  },
  {
    title: "PancakeSwap",
    image: "images/exchanges/pancakeswap.png",
    link: "https://pancakeswap.finance/swap?outputCurrency=0x2C717059b366714d267039aF8F59125CAdce6D8c",
    width: "100px",
    height: "",
  },
  {
    title: "SushiSwap",
    image: "images/exchanges/sushi.png",
    link: "️https://app.sushi.com/trident/swap?tokens=0x61f95bd637e3034133335C1baA0148E518D438ad&tokens=MATIC&chainId=137",
    width: "110px",
    height: "",
  },
  {
    title: "Bitmart",
    image: "images/exchanges/bitmart.png",
    link: "https://www.bitmart.com/trade/en?symbol=MHUNT_USDT&layout=basic",
    width: "125px",
    height: "",
  },
  {
    title: "QuickSwap",
    image: "images/exchanges/quickswap.png",
    link: "https://quickswap.exchange/#/swap?outputCurrency=0x61f95bd637e3034133335C1baA0148E518D438ad",
    width: "150px",
    height: "",
  },
  {
    title: "ApeSwap",
    image: "images/exchanges/apeswap.png",
    link: "https://apeswap.finance/swap?outputCurrency=0x2C717059b366714d267039aF8F59125CAdce6D8c",
    width: "85px",
    height: "",
  },
  {
    title: "CherrySwap",
    image: "images/exchanges/cherryswap.png",
    link: "https://apeswap.finance/swap?outputCurrency=0x2C717059b366714d267039aF8F59125CAdce6D8c",
    width: "130px",
    height: "",
  },
  {
    title: "Uniswap",
    image: "images/exchanges/uniswap.png",
    link: "https://app.uniswap.org/#/swap?outputCurrency=0xf460d98a3517b45Edd8063dd3847de92a8038aa2",
    width: "160px",
    height: "",
  },
];
const nfts = [
  {
    title: "Binance NFT",
    image: "images/exchanges/binance-nft.png",
    link: "https://www.binance.com/en/nft/collection/metashooter-nft-items-615034174353502208",
    width: "140px",
    height: "",
  },
  {
    title: "Tofu NFT",
    image: "images/exchanges/tofu-nft.png",
    link: "https://tofunft.com/collection/meta-shooter-nft/items",
    width: "160px",
    height: "",
  },
  {
    title: "NFTB",
    image: "images/exchanges/nftb.png",
    link: "https://nftb.io/game/metashooter",
    width: "110px",
    height: "",
  },
  {
    title: "Babylons",
    image: "images/exchanges/babylons.png",
    link: "https://babylons.io/metashooternfts",
    width: "90px",
    height: "",
  },
  {
    title: "NFTrade",
    image: "images/exchanges/nftrade.png",
    link: "https://nftrade.com/assets/bsc/0x8F7A57125E23E7e4E6724D20dc8907a9E18D94A5",
    width: "140px",
    height: "",
  },
  {
    title: "BitKeep",
    image: "images/exchanges/bitkeep.png",
    link: "https://bitkeep.com/en/download?type=0",
    width: "120px",
    height: "",
  },
];
const [active, setActive] = useState(0);
const [animating, setAnimating] = useState(false);

const handleTabs = (tab, animate) => {
  setActive(tab);
  setAnimating(animate);
}

return (
        <div className={styles.container}>
          <h4>Purchase tokens & NFTs</h4>
          <div className={styles.tabsContainer}>
            <span onClick={() => handleTabs(0, true)} className={active === 0 && animating === false ? styles.activeTab : active === 0 && animating ? styles.activeTabWithAnimation : null}>Tokens</span>
            <span onClick={() => handleTabs(1, true)} className={active === 1 && animating === false ? styles.activeTab : active === 1 && animating ? styles.activeTabWithAnimation : null}>NFTs</span>
          </div>
          <div className={styles.innerTokens}>
            {active === 0 ? tokens.map((x, index) => (
              <a className={cn("link", styles.link)} href={x.link} target="_blank" key={index}>
                <div className={styles.imageContainer}>
                <img
                      className={styles.pic}
                      src={x.image}
                      alt={x.title}
                      style={{width: `${x.width}`}}
                />
                <span>{x.title}</span>
                </div>
              </a>
            )) :
            nfts.map((x, index) => (
              <a className={cn("link", styles.link)} href={x.link} target="_blank" key={index}>
                <div className={styles.imageContainer}>
                <img
                      className={styles.pic}
                      src={x.image}
                      alt={x.title}
                      style={{width: `${x.width}`}}
                />
                <span>{x.title}</span>
                </div>
              </a>
            ))
            
            }
          </div>
        </div>
      ); 
};

export default PurchaseTokens;