import { useState, useEffect } from 'react';
import {Contract } from "@ethersproject/contracts";


export function useStakeCount(contract: Contract | null, account?: null | string): number{
    const [stakeCount, setStakeCount] = useState(0);

    const getStakeCount = async (stakeCount: number, contract: Contract | null, account?: null | string) => {
        if (contract && account && stakeCount == 0) {
            try {
                contract.stakerStakeCount(account).then((value: any) => {
                    setStakeCount(value.toNumber());
                })
            }
            catch (error) {
                console.log("Error getting stake count", account, error)
            }
        }
    }

    useEffect(() => {
        getStakeCount(stakeCount, contract, account)
    }, [stakeCount, contract, account]);

  return stakeCount;
}