import { useState, useEffect } from 'react';
import {useTokenContract} from "./useContract";
import {BigNumber} from "@ethersproject/bignumber";

export function useBalance(account?: null | string) {
    const [balance, setBalance] = useState(BigNumber.from(0));
    const tokenContract = useTokenContract( true)

    const getBalance = (account?: null | string) => {
        if (tokenContract && account) {
            tokenContract.balanceOf(account).then((value: any) => {
                setBalance(BigNumber.from(value));
            }).catch((e: any) => {
                console.log("Error getting balance", e)
            })
        }
    }

  useEffect(() => {
      getBalance(account)
  }, [account]);


  return balance;
}