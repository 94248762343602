import React, {useState, useEffect} from "react";
import cn from "classnames";
import styles from "./InfoBlock.module.sass";
import { Link } from "react-router-dom";
import Clock from "./Clock";
import { atcb_init } from "add-to-calendar-button";
import 'add-to-calendar-button/assets/css/atcb.css';
import Title from "../Title";

const desktop = <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M49.8276 10H14.1724C13.0664 10.002 12.0063 10.4422 11.2243 11.2243C10.4422 12.0063 10.002 13.0664 10 14.1724V39.5331C10.002 40.6391 10.4422 41.6992 11.2243 42.4812C12.0063 43.2633 13.0664 43.7035 14.1724 43.7055H26.1662L23.5793 52.4828H20.78C20.5788 52.4828 20.3858 52.5627 20.2436 52.705C20.1013 52.8472 20.0214 53.0402 20.0214 53.2414C20.0214 53.4426 20.1013 53.6355 20.2436 53.7778C20.3858 53.9201 20.5788 54 20.78 54H43.22C43.4212 54 43.6142 53.9201 43.7564 53.7778C43.8987 53.6355 43.9786 53.4426 43.9786 53.2414C43.9786 53.0402 43.8987 52.8472 43.7564 52.705C43.6142 52.5627 43.4212 52.4828 43.22 52.4828H40.4207L37.8338 43.7055H49.8276C50.9336 43.7035 51.9937 43.2633 52.7757 42.4812C53.5578 41.6992 53.998 40.6391 54 39.5331V14.1724C53.998 13.0664 53.5578 12.0063 52.7757 11.2243C51.9937 10.4422 50.9336 10.002 49.8276 10ZM14.1724 11.5172H49.8276C50.1763 11.5172 50.5215 11.5859 50.8437 11.7194C51.1658 11.8528 51.4585 12.0484 51.7051 12.2949C51.9516 12.5415 52.1472 12.8342 52.2806 13.1563C52.4141 13.4785 52.4828 13.8237 52.4828 14.1724V36.8779H11.5172V14.1724C11.5172 13.4682 11.797 12.7929 12.2949 12.2949C12.7929 11.797 13.4682 11.5172 14.1724 11.5172ZM25.1724 52.4828L27.4483 44.7828C27.8156 45.6954 28.4474 46.4773 29.2626 47.0279C30.0778 47.5786 31.039 47.8728 32.0228 47.8728C33.0065 47.8728 33.9678 47.5786 34.7829 47.0279C35.5981 46.4773 36.23 45.6954 36.5972 44.7828L38.8276 52.4828H25.1724ZM28.6924 43.7055H35.3379C35.1678 44.4597 34.7462 45.1337 34.1424 45.6165C33.5385 46.0993 32.7883 46.3624 32.0152 46.3624C31.242 46.3624 30.4918 46.0993 29.888 45.6165C29.2841 45.1337 28.8625 44.4597 28.6924 43.7055ZM49.8428 42.1883H14.1724C13.4682 42.1883 12.7929 41.9085 12.2949 41.4106C11.797 40.9127 11.5172 40.2373 11.5172 39.5331V38.3952H52.4828V39.5331C52.4828 39.8818 52.4141 40.2271 52.2806 40.5492C52.1472 40.8713 51.9516 41.164 51.7051 41.4106C51.4585 41.6571 51.1658 41.8527 50.8437 41.9862C50.5215 42.1196 50.1763 42.1883 49.8276 42.1883H49.8428Z" fill="white"/>
</svg>
const mobile = <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M41.8751 10H23.3172C21.6717 10 20.333 11.3387 20.333 12.9842V51.0159C20.333 52.6613 21.6717 54 23.3172 54H41.8751C43.5206 54 44.8593 52.6613 44.8593 51.0159V12.9842C44.8593 11.3387 43.5206 10 41.8751 10ZM23.3172 11.2168H41.8751C42.8497 11.2168 43.6425 12.0096 43.6425 12.9842V13.1867H21.5498V12.9842C21.5498 12.0096 22.3427 11.2168 23.3172 11.2168ZM43.6425 14.4034V48.3869H21.5498V14.4034H43.6425ZM41.8751 52.7832H23.3172C22.3427 52.7832 21.5498 51.9904 21.5498 51.0159V49.6037H43.6425V51.0159C43.6425 51.9904 42.8497 52.7832 41.8751 52.7832Z" fill="white"/>
<path d="M34.7502 50.584H30.4424C30.1064 50.584 29.834 50.8563 29.834 51.1924C29.834 51.5284 30.1064 51.8008 30.4424 51.8008H34.7502C35.0862 51.8008 35.3586 51.5284 35.3586 51.1924C35.3586 50.8563 35.0862 50.584 34.7502 50.584Z" fill="white"/>
</svg>
 const hunter = <img height="50" src="/images/hunter-token.png" />


const Notifications = () => {

  let year = new Date().getFullYear();

  const difference1 = +new Date(`${year}-08-15`) - +new Date();
  const difference2 = +new Date(`${year}-08-01`) - +new Date();

  useEffect(atcb_init, []);
  return (
    <div className={cn("section", styles.section)}>
        <Title>Game releases & updates</Title>
        <div className={cn("container", styles.container)}>
            <div className={styles.greenInnerInfoBlock}>
                <h4 className={styles.title}>Desktop Early-access version</h4>
                <Clock difference={difference1} />
            </div>
            <div className={styles.greenInnerInfoBlock}>
                <h4 className={styles.title}>Mobile early access release</h4>
                <Clock difference={difference2} />
            </div>
        </div>
        <div className={cn("container", styles.container)}>
            <div className={styles.greenInnerInfoBlock}>
                <span className={styles.icon}>{desktop}</span>
                <h4 className={styles.title}>Desktop Early-access version</h4>
                <p>We are pleased to announce that the first version of MetaShooter with NFT Connection (BETA) is now available for you to try out.</p>
                <a href="/">Learn more</a>
            </div>
            <div className={styles.greenInnerInfoBlock}>
                <span className={styles.icon}>{hunter}</span>
                <h4 className={styles.title}>Desktop Early-access version</h4>
                <p>We are pleased to announce that the first version of MetaShooter with NFT Connection (BETA) is now available for you to try out.</p>
                <a href="/">Learn more</a>
            </div>
            <div className={styles.greenInnerInfoBlock}>
                <span className={styles.icon}>{mobile}</span>
                <h4 className={styles.title}>Desktop Early-access version</h4>
                <p>We are pleased to announce that the first version of MetaShooter with NFT Connection (BETA) is now available for you to try out.</p>
                <a href="/">Learn more</a>
            </div>
        </div>
    </div>
  );
};

export default Notifications;
