import React, { useState, useEffect } from "react";
import { utils } from 'ethers';
import { useWeb3React } from "@web3-react/core"
import styles from "./ActionBar.module.sass";
import Dropdown from "../../Dropdown"
import Notifications from "../../Notifications"
import {setStorageNfts} from "../../../functions/nfts";


const ActionBar = (props) => {
	const web3reactContext = useWeb3React();
  const loggedIn = localStorage.getItem('accessToken');
  const { active, chainId, account, library } = useWeb3React();
  const dateOptions =
  [
    {
        "id": 137,
        "img": "/images/chains/matic.png",
        "name": "Polygon / Matic",
    },
    {
        "id": 56,
        "img": "/images/chains/bnb.png",
        "name": "BNB Smart chain",
    },
    {
        "id": 66,
        "img": "/images/chains/okex.png",
        "name": "OKC",
    },
    {
        "id": 1,
        "img": "/images/chains/ethereum.png",
        "name": "Ethereum",
    },
  ];
  const [date, setDate] = useState(localStorage?.getItem('selectedChain'));

  useEffect(() => {
    if(date === '/images/chains/matic.png') {
      const switchNetwork = async () => {
        try {
          await library.provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: utils.hexValue(137) }],
          });
          setStorageNfts(chainId, JSON.stringify([]));
          window.location.reload();
          localStorage.setItem('chainId', web3reactContext.chainId);
        } catch (switchError) {
          // 4902 error code indicates the chain is missing on the wallet
          if (switchError.code === 4902) {
            try {
              await library.provider.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainName: 'Polygon Mainnet',
                    chainId: utils.hexValue(137),
                    nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
                    rpcUrls: ['https://polygon-rpc.com/']
                  }
                ],
              });
              window.location.reload();
              localStorage.setItem('chainId', web3reactContext.chainId);
            } catch (error) {
               alert(error)
            }
          }
        }
      };
      switchNetwork()
    } else if(date === '/images/chains/bnb.png') {
      const switchNetwork = async () => {
        try {
          await library.provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: utils.hexValue(56) }],
          });
          setStorageNfts(chainId, JSON.stringify([]));
          window.location.reload();
        } catch (switchError) {
          // 4902 error code indicates the chain is missing on the wallet
          if (switchError.code === 4902) {
            try {
              await library.provider.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainName: 'Smart Chain',
                    chainId: utils.hexValue(56),
                    nativeCurrency: { name: 'BNB', decimals: 18, symbol: 'BNB' },
                    rpcUrls: ['https://bsc-dataseed.binance.org/']
                  }
                ],
              });
            } catch (error) {
               alert(error)
            }
          }
        }
      };
      switchNetwork()
    }
    else if(date === '/images/chains/okex.png') {
      const switchNetwork = async () => {
        try {
          await library.provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: utils.hexValue(66) }],
          });
          setStorageNfts(chainId, JSON.stringify([]));
          window.location.reload();
        } catch (switchError) {
          // 4902 error code indicates the chain is missing on the wallet
          if (switchError.code === 4902) {
            try {
              await library.provider.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainName: 'OKExChain',
                    chainId: utils.hexValue(66),
                    nativeCurrency: { name: 'OKExChain', decimals: 18, symbol: 'OKT' },
                    rpcUrls: ['https://exchainrpc.okex.org']
                  }
                ],
              });
            } catch (error) {
               alert(error)
            }
          }
        }
      };
      switchNetwork()
    }
    else if(date === '/images/chains/ethereum.png') {
      const switchNetwork = async () => {
        try {
          await library.provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: utils.hexValue(1) }],
          });
          setStorageNfts(chainId, JSON.stringify([]));
          window.location.reload();
        } catch (switchError) {
          // 4902 error code indicates the chain is missing on the wallet
          if (switchError.code === 4902) {
            try {
              await library.provider.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainName: 'ETH',
                    chainId: utils.hexValue(1),
                    nativeCurrency: { name: 'Ether', decimals: 18, symbol: 'ETH' },
                    rpcUrls: ['https://api.mycryptoapi.com/eth']
                  }
                ],
              });
            } catch (error) {
               alert(error)
            }
          }
        }
      };
      switchNetwork()
    }

  }, [date]);

  return (
        <div className={styles.topSmallBar}>
          { active && loggedIn ? <Dropdown
                className={styles.dropdownCurrency}
                value={date}
                setValue={setDate}
                options={dateOptions}
            /> : null }
          { active && loggedIn ? <Notifications
                className={styles.dropdownNotifications}
                value={date}
                setValue={setDate}
                options={dateOptions}
            /> : null }
        </div>
  );
};

export default ActionBar;