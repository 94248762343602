import {useState, useEffect} from 'react';
import {Contract as MultiContract} from 'ethers-multicall';
import {useNFTMultiContract, useNFTContract} from "./useContract";
import {useWeb3React} from "@web3-react/core";
import {useReservedItems} from "./useReservedItems";
import { itemIdMap } from "../constants/items";
import { BigNumber } from '@ethersproject/bignumber'
import {NFT} from "../constants/interfaces";
import {getCallProvider} from "../functions/contract";


let cache = new Map<string, NFT[] | undefined >();

export function useReservedNFTWallet(account?: null | string): any {
    const { chainId } = useWeb3React();
    const reservedNFTs: NFT[] = [];
    const [loaded, setLoaded] = useState(false);
    const [reservedWallet, setReservedWallet] = useState(reservedNFTs);
    const reservedItems = useReservedItems(useNFTContract(), account)
    const nftContractMulti = useNFTMultiContract();
    const callProvider = getCallProvider(chainId, window);

    const getReservedWallet = async (reservedItems: number[], nftContractMulti: MultiContract | null, account?: null | string) => {
        if (reservedItems.length > 0 && account && nftContractMulti && !loaded && reservedWallet.length == 0) {
            let accountCache = cache.get(account);
            if (accountCache) {
                setReservedWallet(accountCache);
            } else {
                let calls = [];
                for (let i = 0; i < reservedItems.length; i++) {
                    calls.push(nftContractMulti.reservedBalance(account, reservedItems[i]))
                }
                try {
                    const callResults: any = await callProvider.all(calls);
                    const formattedWallet = formatWallet(reservedItems, callResults);
                    setReservedWallet(formattedWallet);
                    setLoaded(true);
                    cache.set(account, formattedWallet)
                }
                catch (error) {
                    console.error("Error getting reserved wallet", error)
                }
            }
        }
    }

    const formatWallet = (itemIds: number[], quantities: BigNumber[]): NFT[] => {
        let wallet: NFT[] = [];
        for (let i = 0; i < itemIds.length; i++) {
            let nft = itemIdMap.get(itemIds[i]);
            if (nft){
                for (let j = 0; j < quantities[i].toNumber(); j++) {
                    const newNft = { ...nft } as NFT;
                    wallet.push(newNft)
                }
            }
        }
        return wallet;
    }

    useEffect(() => {
        getReservedWallet(reservedItems, nftContractMulti, account)
    }, [reservedItems, nftContractMulti, account]);

    return reservedWallet;
}