import axios from "axios";
import { atom, selector } from "recoil";
import { getApiConfig } from "./functions/api";

export const fetchHunterGameBalance = selector({
  key: 'fetchHunterGameBalance',
  get: async ({ get }) => {
    return await axios.get("game/balance/", getApiConfig()
    ).then((response) => {
        return parseFloat(response.data?.balance ?? 0);
    }).catch((error) => {
        console.log("Error gettting game balance", error)
        return 0
    })
  }
});

export const hunterGameBalanceState = atom({
  key: 'hunterGameBalance',
  default: fetchHunterGameBalance,
});
