import React, { useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link, NavLink } from "react-router-dom";
import Image from "../Image";
import Connect from "./Connect"
import FixedMobileMenu from "./FixedMobileMenu";
import Social from "./Social";
import ActionBar from "./ActionBar"
import { isMobile } from 'react-device-detect';
import { useWeb3React } from "@web3-react/core"

const Header = ({ path, separatorHeader, wide }) => {
  const [visibleNav, setVisibleNav] = useState(false);
  const web3reactContext = useWeb3React();
  const [currentAccount, setCurrentAccount] = useState(null);
  const loggedIn = localStorage.getItem('accessToken');
  var windowWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  console.log(windowWidth);
  return (
    <>
      <div
        className={cn(
          styles.header,
          { [styles.headerBorder]: separatorHeader },
          { [styles.wide]: wide }
        )}
      >
          <Link className={styles.logoMobile} to="/">
              <Image
                className={styles.pic}
                src="/images/logo-dark.svg"
                srcDark="/images/logo-light.svg"
                alt="Metashooter"
              />
          </Link>
        <div className={cn("container", styles.container)}>
          <div className={cn(styles.wrapper, { [styles.active]: visibleNav })}>
            <Link className={styles.logo} to="/">
              <Image
                className={styles.pic}
                src="/images/logo-dark.svg"
                srcDark="/images/logo-light.svg"
                alt="Metashooter"
              />
            </Link>
            { windowWidth > 767 ? <ActionBar /> : null}
          </div>
          { (path == "/mystery-box-checker" || path == "/")  ? null :
              <div className={web3reactContext.active && loggedIn ? styles.mobileBottomConnected : styles.mobileBottomNotConnected}>
                <Connect setCurrentAccount={setCurrentAccount} />
              </div> }
          { windowWidth < 767 ? <ActionBar /> : null}
          <button
            className={cn(styles.burger, { [styles.active]: visibleNav })}
            onClick={() => setVisibleNav(!visibleNav)}
          ></button>
        </div>
      </div>
    </>
  );
};

export default Header;
