import {useState, useEffect} from 'react';
import {Contract as MultiContract} from 'ethers-multicall';
import {useClaimMultiContract, useClaimContract} from "./useContract";
import {useStakeRewardCount} from "./useStakeRewardCount";
import { useWeb3React } from "@web3-react/core"
import {CLAIM_HUNTERS_V3_ADDRESS, CLAIM_V2_ADDRESS, CLAIM_V3_ADDRESS} from "../constants/addresses";
import {getCallProvider} from "../functions/contract";
import { StakeToken } from './useStakeRecords';

let cache = new Map<string, Map<string, StakeRewardRecord[] | undefined >>();

export interface StakeRewardRecord {
    amount: number;
    claimId: number;
    claimedMonthsCount: number;
    timestamp: number;
}

export function useStakeRewardRecords(account?: null | string, stakeToken?: StakeToken): StakeRewardRecord[] {
    const { chainId } = useWeb3React();
    const stakeRewardCount = useStakeRewardCount(useClaimContract(CLAIM_V2_ADDRESS), account)
    const stakeRewardCountV3 = useStakeRewardCount(useClaimContract(CLAIM_V3_ADDRESS), account)
    const stakeHuntersRewardCountV3 = useStakeRewardCount(useClaimContract(CLAIM_HUNTERS_V3_ADDRESS), account)
    const stakeRewardInfo: StakeRewardRecord[] = [];
    const [rewards, setRewards] = useState(stakeRewardInfo);
    const [rewardsV3, setRewardsV3] = useState(stakeRewardInfo);
    const [rewardsHuntersV3, setRewardsHuntersV3] = useState(stakeRewardInfo);
    const claimContractMulti = useClaimMultiContract(CLAIM_V2_ADDRESS);
    const claimContractMultiV3 = useClaimMultiContract(CLAIM_V3_ADDRESS);
    const claimHuntersContractMultiV3 = useClaimMultiContract(CLAIM_HUNTERS_V3_ADDRESS);
    const callProvider = getCallProvider(chainId, window);

    const getStakeRewardInfo = async (stakeRewardCount: number, claimContractMulti: MultiContract | null, setRewards: any, version: string, account?: null | string) => {
        if (stakeRewardCount > 0 && account && claimContractMulti) {
            let accountCache = cache.get(account);
            if (accountCache && accountCache.has(version)) {
                setRewards(accountCache.get(version));
            } else {
                let calls = [];
                for (let i = 0; i < stakeRewardCount; i++) {
                    calls.push(claimContractMulti.rewards(account, i))
                }
                try {
                    const callResults: any = await callProvider.all(calls);
                    setRewards(callResults);

                    let cacheTemp = new Map<string, StakeRewardRecord[] | undefined >();
                    cacheTemp.set("2", cache.get(account)?.get("2"))
                    cacheTemp.set("3", cache.get(account)?.get("3"))
                    cacheTemp.set(version, callResults)
                    cache.set(account, cacheTemp)
                }
                catch (error) {
                    console.error("Error getting stake rewards", error)
                }
            }
        }
    }

    useEffect(() => {
        if (stakeToken === StakeToken.HUNTERS){
            getStakeRewardInfo(stakeHuntersRewardCountV3, claimHuntersContractMultiV3, setRewardsHuntersV3, "3", account);
        } else if (stakeToken === StakeToken.MHUNT) {
            getStakeRewardInfo(stakeRewardCount, claimContractMulti, setRewards, "2", account);
            getStakeRewardInfo(stakeRewardCountV3, claimContractMultiV3, setRewardsV3, "3", account);
        }
    }, [stakeRewardCount, stakeRewardCountV3, account]);

    if (stakeToken === StakeToken.HUNTERS){
        return rewardsHuntersV3;
    } else if (stakeToken === StakeToken.MHUNT) {
        return [...rewards, ...rewardsV3];
    }
    return [];
}