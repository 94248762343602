import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Badges.module.sass";

const Badges = ({setOpenedSwap}) => {

  const badgesItems = [
    {name: "Bronze 3* badge", image: "/images/available-badge.png", availability: true},
    {name: "Bronze 3* badge", image: "/images/available-badge.png", availability: false},
    {name: "Bronze 3* badge", image: "/images/available-badge.png", availability: false},
    {name: "Bronze 3* badge", image: "/images/available-badge.png", availability: false},
    {name: "Bronze 3* badge", image: "/images/available-badge.png", availability: false},
    {name: "Bronze 3* badge", image: "/images/available-badge.png", availability: false},
    {name: "Bronze 3* badge", image: "/images/available-badge.png", availability: false},
    {name: "Bronze 3* badge", image: "/images/available-badge.png", availability: false},
    {name: "Bronze 3* badge", image: "/images/available-badge.png", availability: false},
  ]
  const swapBadge = (link) => {
    console.log('Pressed swap badge', link);
    setOpenedSwap(true);
  }

  console.log('Badges: ', badgesItems)
 
  return (
    <div className={cn(styles.container)}>
      <div className={styles.innerContainer}>
        <span className={styles.title}>Badges</span>
      </div>
      <div className={styles.badgesContainer}>
        {badgesItems.map((badge, index) => (
          <div className={styles.badgeWrapper} key={index}>
            <img src={badge.image} alt="badge" />
            { badge.availability ? null : <div className={styles.availabilityBackground}></div> }
            { badge.availability ? null : <span className={styles.availabilityText}>This item is not available</span> }
            <div className={badge.availability ? styles.nameActive : styles.name}>
              <span>{badge.name}</span>
              <div  onClick={() => swapBadge(index)} className={styles.getButton}><span>Get this badge</span></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Badges;