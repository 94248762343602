import axios from 'axios';
import { useEffect } from 'react';
import { getApiConfig } from '../functions/api';
import { clearLocalStorage } from '../functions/utils';

export default function useRefreshToken() {
    useEffect(() => {
        const refreshTokens = async () => {
            const refresh = localStorage.getItem('refreshToken');
            const href = window.location.pathname;
            const token = {
                refresh: refresh
            };

            axios.post("/rest-auth/token/refresh/", token, getApiConfig(false))
                .then((response) => {
                    const data = response.data;
                    if (data.access) {
                        localStorage.setItem('accessToken', data.access);
                        localStorage.setItem('items', data.items);
                    } else if (!data.access && href != '/') {
                        console.log('Your session expired, please login again.');
                        clearLocalStorage()
                    }
                }).catch((error) => {
                    if (href != '/') {
                        console.log('Your session expired, please login again.');
                        clearLocalStorage()
                    }
                    console.error(error);
                })
        };
        refreshTokens();
    }, []);
}