import {AddressZero} from '@ethersproject/constants'
import {useMemo} from 'react'
import {useWeb3React} from "@web3-react/core"
import STAKE_ABI from '../constants/abis/MetaShooterStaker.json'
import STAKE_ABI_V3 from '../constants/abis/MetaShooterStakerV3.json'
import CLAIM_ABI from '../constants/abis/MetaShooterClaimer.json'
import SIMPLE_SWAP from '../constants/abis/MetaShooterSimpleSwap.json'
import ERC20_ABI from '../constants/abis/ERC20.json'
import ERC721_ABI from '../constants/abis/ERC721.json'
import NFT_ABI from '../constants/abis/MetaShooterNFT.json'
import MYSTERY_BOX_ABI from '../constants/abis/MetaShooterMysteryBox.json'
import MYSTERY_BOX_OPENER_ABI from '../constants/abis/MetaShooterMysteryBoxOpener.json'
import CRAFTER_ABI from '../constants/abis/MetaShooterCrafter.json'
import GAME_VAULT_ABI from '../constants/abis/MetaShooterGameVault.json'

import {Contract as MultiContract} from 'ethers-multicall';
import {Contract } from "@ethersproject/contracts";
import {getContract} from "../functions/contract";
import {
    AddressMap, MB_ADDRESS, MHUNT_ADDRESS, HUNTERS_ADDRESS, NFT_ADDRESS, STAKE_V3_ADDRESS,
    SWAP_ADDRESS, MB_OPENER_ADDRESS, CRAFTER_ADDRESS, GAME_VAULT_ADDRESS
} from "../constants/addresses";


// returns null on errors
export function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useWeb3React()
  if (!address || address == "" || address === AddressZero || !ABI || !library) return null
  try {
    return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
  } catch (error) {
    console.error('Failed to get contract', error)
    return null
  }
}

export function useMultiContract(address: string | undefined, ABI: any): MultiContract | null {
  if (!address || address == "" || address === AddressZero || !ABI) return null
  try {
    return new MultiContract(address, ABI)
  } catch (error) {
    console.error('Failed to get multi contract', error)
    return null
  }
}

export function useTokenContract(withSignerIfPossible?: boolean): Contract | null {
    const { chainId } = useWeb3React()
    return useContract(chainId ? MHUNT_ADDRESS[chainId] : undefined, ERC20_ABI, withSignerIfPossible)
}

export function useHuntersContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? HUNTERS_ADDRESS[chainId] : undefined, ERC20_ABI, withSignerIfPossible)
}

export function useSwapContract(withSignerIfPossible?: boolean): Contract | null {
    const { chainId } = useWeb3React()
    return useContract(chainId ? SWAP_ADDRESS[chainId] : undefined, SIMPLE_SWAP, withSignerIfPossible)
}

export function useStakeContract(stakeAddress: AddressMap): Contract | null {
    const { chainId } = useWeb3React()
    return useContract(chainId ? stakeAddress[chainId] : undefined, STAKE_ABI, true)
}

export function useStakeMultiContract(stakeAddress: AddressMap): MultiContract | null {
    const { chainId } = useWeb3React()
    return useMultiContract(chainId ? stakeAddress[chainId] : undefined, STAKE_ABI)
}

export function useStakeContractV3(stakeAddress: AddressMap): Contract | null {
    const { chainId } = useWeb3React()
    return useContract(chainId ? stakeAddress[chainId] : undefined, STAKE_ABI_V3, true)
}

export function useStakeMultiContractV3(stakeAddress: AddressMap): MultiContract | null {
    const { chainId } = useWeb3React()
    return useMultiContract(chainId ? stakeAddress[chainId] : undefined, STAKE_ABI_V3)
}

export function useClaimContract(claimerAddress: AddressMap): Contract | null {
    const { chainId } = useWeb3React()
    return useContract(chainId ? claimerAddress[chainId] : undefined, CLAIM_ABI, true)
}

export function useCrafterContract(): Contract | null {
    const { chainId } = useWeb3React()
    return useContract(chainId ? CRAFTER_ADDRESS[chainId] : undefined, CRAFTER_ABI, true)
}

export function useGameVaultContract(withSignerIfPossible?: boolean): Contract | null {
    const { chainId } = useWeb3React()
    return useContract(chainId ? GAME_VAULT_ADDRESS[chainId] : undefined, GAME_VAULT_ABI, withSignerIfPossible)
}

export function useClaimMultiContract(claimerAddress: AddressMap): MultiContract | null {
    const { chainId } = useWeb3React()
    return useMultiContract(chainId ? claimerAddress[chainId] : undefined, CLAIM_ABI)
}

export function useNFTContract(): Contract | null {
    const { chainId } = useWeb3React()
    return useContract(chainId ? NFT_ADDRESS[chainId] : undefined, NFT_ABI, true)
}
export function useNFTMultiContract(): MultiContract | null {
    const { chainId } = useWeb3React()
    return useMultiContract(chainId ? NFT_ADDRESS[chainId] : undefined, NFT_ABI)
}

export function useMysteryBoxContract(staticChainId?: number): Contract | null {
    const { chainId } = useWeb3React()
    if (staticChainId == undefined){
        staticChainId = chainId;
    }
    return useContract(staticChainId ? MB_ADDRESS[staticChainId] : undefined, MYSTERY_BOX_ABI, true)
}

export function useMysteryBoxMultiContract(staticChainId?: number): MultiContract | null {
    const { chainId } = useWeb3React()
    if (staticChainId == undefined){
        staticChainId = chainId;
    }
    return useMultiContract(staticChainId ? MB_ADDRESS[staticChainId] : undefined, MYSTERY_BOX_ABI)
}

export function useMysteryBoxOpenerContract(staticChainId?: number): Contract | null {
    const { chainId } = useWeb3React()
    if (staticChainId == undefined){
        staticChainId = chainId;
    }
    return useContract(staticChainId ? MB_OPENER_ADDRESS[staticChainId] : undefined, MYSTERY_BOX_OPENER_ABI, true)
}

export function useMysteryBoxOpenerMultiContract(staticChainId?: number): MultiContract | null {
    const { chainId } = useWeb3React()
    if (staticChainId == undefined){
        staticChainId = chainId;
    }
    return useMultiContract(staticChainId ? MB_OPENER_ADDRESS[staticChainId] : undefined, MYSTERY_BOX_OPENER_ABI)
}

export function useERC721Contract(address: string): Contract | null {
    return useContract(address, ERC721_ABI)
}