import {Token} from "@sushiswap/core-sdk";


export type AddressMap = { [chainId: number]: string }

export enum ChainId {
  MATIC = 137,
  BSC = 56,
  BSC_TEST = 97,
  OKEX = 66,
  ETH = 1
}

export const MHUNT_ADDRESS: AddressMap = {
  [ChainId.MATIC]: '0x61f95bd637e3034133335c1baa0148e518d438ad',
  [ChainId.BSC]: '0x2C717059b366714d267039aF8F59125CAdce6D8c',
  [ChainId.OKEX]: '0x8057687CBB5D8DDE94a0E11557D5355c4Aecd322',
  [ChainId.BSC_TEST]: '0x5D03985c7EaD1C5Fa930b34B98C1744692AC3257',
  [ChainId.ETH]: '0xf460d98a3517b45Edd8063dd3847de92a8038aa2',  
}

export const HUNTERS_ADDRESS: AddressMap = {
  [ChainId.BSC]: '0x71E044E166793D2391931aF427cF146Fe9e939cf',
}

export const SWAP_ADDRESS: AddressMap = {
  [ChainId.BSC]: '0x547a0857A1b5FAb350528D8C532f24AeE58BE1c1',
}

export const GAME_VAULT_ADDRESS: AddressMap = {
  [ChainId.BSC]: '0xe940d54b90a5c2103b692cb97214190d16da15bb',
}

export const STAKE_ADDRESS: AddressMap = {
  [ChainId.MATIC]: '0xCF6dEd16e4A4a66Bd4408d0CaF520A916a81CF07',
}

export const STAKE_V2_ADDRESS: AddressMap = {
  [ChainId.MATIC]: '0x2529ee7c159aB8Ce7cE6dba9176218695021c082',
  [ChainId.BSC]: '0x9c897b46A4862F04bCaB5a7B52C02E21e85388D1',
  [ChainId.BSC_TEST]: '0x11A591674e04cC796Fc3F4A7D4B811D1482Ba952',
}

export const STAKE_V3_ADDRESS: AddressMap = {
  [ChainId.MATIC]: '0x5a7b40BfDA7774592006c55beA4A0cE2552d0cD2',
  [ChainId.BSC]: '0x7Af892E5536d413b79d5Ec66791203F5018dC773',
  [ChainId.OKEX]: '0x7Fc365d3D39419C852f16542cF177ca4A8B93A09',
  [ChainId.ETH]: '0x625c138EeE92eaeC37E86dBD880393c7c45725E0',
}

export const CLAIM_V2_ADDRESS: AddressMap = {
  [ChainId.MATIC]: '0xE4910343de6876a69C796Fd793b9DfEa445Ab82C',
  [ChainId.BSC]: '0xfD69C36632D3d927dA38fcCFD47736F561A90ac7',
  [ChainId.BSC_TEST]: '0x6bD8a34D52D84036743D9050162b0B3c038105b4',
}

export const CLAIM_V3_ADDRESS: AddressMap = {
  [ChainId.MATIC]: '0xae475Efdd8104E088F38BFbAeb9fE129Df0cA830',
  [ChainId.BSC]: '0x2663a52604A1ce9a55154Df8B21dDF02eB35Cd61',
  [ChainId.OKEX]: '0x8f79753121E755119aAAD8E4048fC5697B344314',
  [ChainId.ETH]: '0xf10b8b6FEF81E5dcAE754871D4F62f3Cae75E7Df',
}

export const STAKE_HUNTERS_V3_ADDRESS: AddressMap = {
  [ChainId.BSC]: '0x1751558a6DFD9AD601FCa5FD4bc9678F2d52f878',
}

export const CLAIM_HUNTERS_V3_ADDRESS: AddressMap = {
  [ChainId.BSC]: '0x031303059385Fae0f5b95A58195f405403543Cbb',
}

export const NFT_ADDRESS: AddressMap = {
  [ChainId.BSC]: '0x8F7A57125E23E7e4E6724D20dc8907a9E18D94A5',
  [ChainId.BSC_TEST]: '0x501268e941BAd77A980C0A36A8788B7e72F4E8b6',
}

export const MB_ADDRESS: AddressMap = {
  [ChainId.BSC]: '0x6551f3DFCEb3AE7C02b22900e2f0b4Dc569FFbEf',
  [ChainId.BSC_TEST]: '0x6bD8a34D52D84036743D9050162b0B3c038105b1',
}

export const MB_OPENER_ADDRESS: AddressMap = {
  [ChainId.BSC]: '0x1D2a561eA5E6F6F06a0562c16943300d940C9925',
}

export const CRAFTER_ADDRESS: AddressMap = {
  [ChainId.BSC]: '0xcfC31657EFd23E6f5CBbBF52C9A2b44BBb9d0F86',
}

export function getMhuntToken(chainId?: ChainId): Token {
  if (chainId && MHUNT_ADDRESS[chainId] !== undefined){
    return new Token(chainId, MHUNT_ADDRESS[chainId], 18, 'MHUNT', 'MetaShooter Token')
  }
  return new Token(ChainId.BSC, MHUNT_ADDRESS[ChainId.MATIC], 18, 'MHUNT', 'MetaShooter Token')
}

export function getHunterToken(chainId?: ChainId): Token {
  if (chainId && HUNTERS_ADDRESS[chainId] !== undefined){
    return new Token(chainId, HUNTERS_ADDRESS[chainId], 18, 'HUNTERS', 'MetaShooter Reward Token')
  }
  return new Token(ChainId.BSC, HUNTERS_ADDRESS[ChainId.BSC], 18, 'HUNTERS', 'MetaShooter Reward Token')
}