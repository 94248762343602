import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Main.module.sass";
import AccountInfo from "./AccountInfo"
import { useReservedNFTWallet } from "../../hooks/useReservedNFTWallet"
import { useOwnedNFTWallet } from "../../hooks/useOwnedNFTWallet"
import { useOwnedBoxesWallet } from "../../hooks/useOwnedBoxesWallet"
import { useWeb3React } from "@web3-react/core"
import LogRocket from 'logrocket'
import { isMobile } from 'react-device-detect';

const randomNumber = Math.floor(Math.random() * 10000);

let avatarText = localStorage.getItem('username') ?? `MetaHunter #${randomNumber}`;

const Main = (props) => {
  const { account, chainId } = useWeb3React();
  const [confirm, setConfirm] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [avatar, setAvatar] = useState(localStorage.getItem('avatar'));
  const token = localStorage.getItem('accessToken');
  const email = localStorage.getItem('email');

  LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
    name: avatarText,
    email: email,

    subscriptionType: 'pro'
  });

  function handleSubmit(event) {
    event.preventDefault();
    console.log('Sending file to backend...');
    const formData = new FormData();
    formData.append('avatar', selectedFile);
    fetch(
      'https://api.hunter.metashooter.gg/user/profile/avatar/',
      {
        method: 'PUT',
        headers: {
          'authorization': `Bearer ${token}`,
        },
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setAvatar(result.avatar);
        localStorage.setItem('avatar', result.avatar);
        setConfirm(false);
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    setConfirm(true);
  }
  const reload = () => {
    window.location.reload(false);
    window.location.href = "/wallet";
  }
  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        {/* <div className={styles.hero}>
          <picture>
                <img src="/images/content/main-pic-2.jpg" alt="Main" />
          </picture>
          </div> */}
        <div className={styles.top} style={isMobile ? {"backgroundImage" : "url(/images/content/mobile-top-Cover.jpg)"} : {"backgroundImage" : "url(/images/content/main-pic-2.jpg)"}}>
          <div className={styles.avatar}>
              <div className={styles.avatarInner}>
              {avatar !== 'null' 
              ?
                  <img src={avatar} alt={avatarText
                    .substring(0, 2)} />
              : <span className={styles.avatarLetters}>{avatarText.substring(0, 2)}</span> 
              }
              </div>
              <div className={styles.changeAvatar}>
                <span>Change</span>
                <form className={styles.formFileInput} onSubmit={handleSubmit}>
                  <input type="file" onChange={handleFileSelect}/>
                </form>
              </div>
          </div>
          <AccountInfo />
        </div>
      </div>
    </div>
  );
};

export default Main;