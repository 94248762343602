import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./SwapBadges.module.sass";

const swapArrow = <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="37.5" width="37" height="37" rx="7.5" transform="rotate(-90 0.5 37.5)" fill="#323232" stroke="#323232"/>
<path d="M26.7071 19.7071C27.0976 19.3166 27.0976 18.6834 26.7071 18.2929L20.3431 11.9289C19.9526 11.5384 19.3195 11.5384 18.9289 11.9289C18.5384 12.3195 18.5384 12.9526 18.9289 13.3431L24.5858 19L18.9289 24.6569C18.5384 25.0474 18.5384 25.6805 18.9289 26.0711C19.3195 26.4616 19.9526 26.4616 20.3431 26.0711L26.7071 19.7071ZM11 20L26 20L26 18L11 18L11 20Z" fill="white"/>
</svg>


const SwapBadges = () => {

  const swapBadge = (link) => {
    console.log('Pressed swap badge', link)
  }
 
  return (
    <div className={cn(styles.container)}>
      <div className={styles.innerContainer}>
        <span className={styles.title}>Confirm badge swap</span>
      </div>
      <div className={styles.badgesContainer}>
        <div className={styles.swapContainer}>
          <img height={96} src="/images/swap-badge.png" alt="" />
          {swapArrow}
          <img height={96} src="/images/swap-badge.png" alt="" />
        </div>
        <span className={styles.title1}>Waiting for confirmation</span>
        <span className={styles.title2}>If you want to swap your badge to “BADGE NAME”, your old badge will be burned</span>
        <button onClick={() => swapBadge()} className={cn("button", styles.button)}>Confirm transaction</button>
      </div>
    </div>
  );
};

export default SwapBadges;