import React, {useState} from "react";
import cn from "classnames";
import styles from "./Warning.module.sass";
import Connect from "../Header/Connect"
import { isWalletMerged } from "../../functions/utils";


const Warning = ({ text, className = ""}) => {
  const [currentAccount, setCurrentAccount] = useState(null);
  const merged = isWalletMerged();
  return (
    <div className={cn("warning", styles.sectionWarning)}>
        <div className={cn(className, styles.container)}>
        <div className={cn(className, styles.symbol)}>!</div>
        { merged ? <p>You should connect your wallet to start { text }</p> : <p>You should merge your wallet to start { text }</p>}
        <div className={cn(className, styles.connect)}><Connect setCurrentAccount={setCurrentAccount} /></div>
        </div>
    </div>
  );
};

export default Warning;
