import { Network } from "@ethersproject/networks";
import {AddressMap, ChainId} from "./addresses";


export function getChainExplorer(chainId?: ChainId): string {
  return chainId ? CHAIN_EXPLORER[chainId] : CHAIN_EXPLORER[ChainId.MATIC]
}

export function getNetworkName(chainId?: ChainId): string {
  return chainId ? NETWORK_NAME[chainId] : NETWORK_NAME[ChainId.MATIC]
}

export const matic: Network = {
    name: 'matic',
    chainId: ChainId.MATIC,
    _defaultProvider: (providers) => new providers.JsonRpcProvider('https://polygon-rpc.com', ChainId.MATIC)
}

export const bsc: Network = {
    name: 'bsc',
    chainId: ChainId.BSC,
    _defaultProvider: (providers) => new providers.JsonRpcProvider('https://bsc-dataseed.binance.org/', ChainId.BSC)
}

export const bsc_test: Network = {
    name: 'bsc',
    chainId: ChainId.BSC_TEST,
    _defaultProvider: (providers) => new providers.JsonRpcProvider('https://data-seed-prebsc-1-s2.binance.org:8545/', ChainId.BSC_TEST)
}

export const okex: Network = {
    name: 'okc',
    chainId: ChainId.OKEX,
    _defaultProvider: (providers) => new providers.JsonRpcProvider('https://exchainrpc.okex.org', ChainId.OKEX)
}

export const NETWORK_PROVIDER: { [chainId: number]: Network } = {
  [ChainId.MATIC]: matic,
  [ChainId.BSC]: bsc,
  [ChainId.BSC_TEST]: bsc_test,
  [ChainId.OKEX]: okex,
}

export const NETWORK_NAME: AddressMap = {
  [ChainId.MATIC]: "Polygon",
  [ChainId.BSC]: "BSC",
  [ChainId.BSC_TEST]: "TBSC",
  [ChainId.OKEX]: "OKEX",
}

export const CHAIN_EXPLORER: AddressMap = {
  [ChainId.MATIC]: 'https://polygonscan.com',
  [ChainId.BSC]: 'https://bscscan.com',
  [ChainId.BSC_TEST]:  'https://testnet.bscscan.com',
  [ChainId.OKEX]:  'https://www.oklink.com/en/okc',
  [ChainId.ETH]:  'https://etherscan.io',
}