import {useState, useEffect} from 'react';
import {Contract} from "@ethersproject/contracts";


export function useStakeRewardCount(contract: Contract | null, account?: null | string): number {
    const [stakeRewardCount, setStakeRewardCount] = useState(-1);

    const getStakeRewardCount = async (stakeCount: number, contract: Contract | null, account?: null | string) => {
        if (contract && account && stakeRewardCount == -1) {

            contract.stakerRewardsCount(account)
                .then((value: any) => {
                    setStakeRewardCount(value.toNumber());
                })
                .catch((e: any) => {
                    setStakeRewardCount(0);
                })
        }
    }

    useEffect(() => {
        getStakeRewardCount(stakeRewardCount, contract, account)
    }, [stakeRewardCount, contract, account]);

    return stakeRewardCount;
}