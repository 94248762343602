import React, { useState, useEffect } from "react";
import cn from "classnames";
import { utils } from "ethers";
import { useWeb3React } from "@web3-react/core";
import styles from "./Connect.module.sass";
import { NavLink } from "react-router-dom";
import { injected, walletconnect, resetWalletConnector, walletlink, binance, portis } from "../../wallet/Connectors";
import PopUp from "../../Popup";
import useClipboard from "react-use-clipboard";
import { getChainExplorer, getNetworkName } from "../../../constants/networks";
import Dropdown from "../../Dropdown";
import Notifications from "../../Notifications";
import ReactLoading from "react-loading";
import { isMobile } from "react-device-detect";

const Connect = (props) => {
  const web3reactContext = useWeb3React();
  const loggedIn = true;
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const { active, account, chainId, library } = useWeb3React();
  const [selectChainId, setSelectChainId] = useState(false);
  const [selectChainId2, setSelectChainId2] = useState(false);
  const [connectedChainId, setConnectedChainId] = useState("");
  const [mergeError, setMergeError] = useState("");
  const [loading, setLoading] = useState(false);
  const metamask = localStorage.getItem("metamask") ?? "";
  const [isItsuccess, setIsItsuccess] = useState(false);
  const [isCopied, setCopied] = useClipboard(account, {
    successDuration: 1000,
  });

  const connectMetaMaskAdvanced = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (window.ethereum) {
      if (
        window.ethereum.networkVersion == 137 ||
        window.ethereum.networkVersion == 56 ||
        window.ethereum.networkVersion == 97 ||
        window.ethereum.networkVersion == 66 ||
        window.ethereum.networkVersion == 1
      ) {
        connectMetamaskSimple();
        setConnectedChainId("MetaMask");
      } else {
        setSelectChainId(true);
        setConnectedChainId("MetaMask");
      }
    } else {
      if (isMobile) {
        if (/android/i.test(userAgent)) {
          //window.location.href = 'https://play.google.com/store/apps/details?id=io.metamask&hl=en_US&gl=US';
          window.location.href = "metamask://";
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          //window.location.href = 'https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202';
          window.location.href = "metamask://";
        }
      } else {
        alert("You do not have MetaMask installed. Please install it and try again.");
      }
    }
  };

  const connectTrustWalletAdvanced = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (window.ethereum) {
      if (
        window.ethereum.networkVersion == 137 ||
        window.ethereum.networkVersion == 56 ||
        window.ethereum.networkVersion == 97 ||
        window.ethereum.networkVersion == 66 ||
        window.ethereum.networkVersion == 1
      ) {
        connectMetamaskSimple();
        setConnectedChainId("Trust Wallet");
      } else {
        setSelectChainId2(true);
        setConnectedChainId("Trust Wallet");
      }
    } else {
      if (isMobile) {
        if (/android/i.test(userAgent)) {
          window.location.href = "https://play.google.com/store/apps/details?id=com.wallet.crypto.trustapp&hl=en&gl=US";
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          window.location.href = "https://apps.apple.com/us/app/trust-crypto-bitcoin-wallet/id1288339409";
        }
      } else {
        alert("You do not have Trust Wallet installed. Please install it and try again.");
      }
    }
  };

  //web3react metamask
  const connectMetamaskSimple = async () => {
    try {
      await web3reactContext.activate(injected);
      localStorage.setItem("connectedWallet", "injected");
      setVisible(false);
      localStorage.setItem("isWalletConnected", true);
      props.setCurrentAccount(true);
    } catch (ex) {
      alert(ex);
    }
  };
  const connectMetamaskPolygon = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x89" }],
      });
      await web3reactContext.activate(injected);
      localStorage.setItem("connectedWallet", "injected");
      setVisible(false);
      localStorage.setItem("isWalletConnected", true);

      props.setCurrentAccount(true);
    } catch (ex) {
      alert(ex);
    }
  };

  const connectMetamaskBinance = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x38" }],
      });
      await web3reactContext.activate(injected);
      localStorage.setItem("connectedWallet", "injected");
      setVisible(false);
      localStorage.setItem("isWalletConnected", true);

      props.setCurrentAccount(true);
    } catch (ex) {
      alert(ex);
    }
  };

  const connectMetamaskOkc = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x42" }],
      });
      await web3reactContext.activate(injected);
      localStorage.setItem("connectedWallet", "injected");
      setVisible(false);
      localStorage.setItem("isWalletConnected", true);

      props.setCurrentAccount(true);
    } catch (ex) {
      alert(ex);
    }
  };

  const connectMetamaskEth = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x1" }],
      });
      await web3reactContext.activate(injected);
      localStorage.setItem("connectedWallet", "injected");
      setVisible(false);

      localStorage.setItem("isWalletConnected", true);

      props.setCurrentAccount(true);
    } catch (ex) {
      alert(ex);
    }
  };

  //web3react walletconnect
  const connectWalletConnectSimple = async () => {
    try {
      await web3reactContext.activate(injected);
      localStorage.setItem("connectedWallet", "injected");
      setConnectedChainId("Wallet Connect");
      setVisible(false);
      localStorage.setItem("isWalletConnected", true);

      props.setCurrentAccount(true);
    } catch (ex) {
      alert(ex);
    }
  };
  //web3react coinbase
  const connectCoinbaseSimple = async () => {
    try {
      await web3reactContext.activate(walletlink);
      localStorage.setItem("connectedWallet", "walletlink");
      setConnectedChainId("CoinBase");
      setVisible(false);
      localStorage.setItem("isWalletConnected", true);

      props.setCurrentAccount(true);
    } catch (ex) {
      alert(ex);
    }
  };
  const connectPortisSimple = async () => {
    try {
      await web3reactContext.activate(portis);
      localStorage.setItem("connectedWallet", "walletlink");
      setConnectedChainId("Portis");
      setVisible(false);
      localStorage.setItem("isWalletConnected", true);

      props.setCurrentAccount(true);
    } catch (ex) {
      alert(ex);
    }
  };
  const connectBinanceSimple = async () => {
    try {
      await web3reactContext.activate(binance);
      localStorage.setItem("connectedWallet", "binance");
      setConnectedChainId("Binance");
      setVisible(false);
      localStorage.setItem("isWalletConnected", true);

      props.setCurrentAccount(true);
    } catch (ex) {
      alert(ex);
    }
  };
  const disconnect = () => {
    try {
      web3reactContext.deactivate();
      localStorage.setItem("isWalletConnected", false);
      localStorage.removeItem("connectedWallet");
      localStorage.removeItem("chainId");
      setConnectedChainId("");
      setSelectChainId(false);
      props.setCurrentAccount(false);
      window.location.reload();
    } catch (ex) {
      alert(ex);
    }
  };
  const disconnectNoReload = () => {
    try {
      web3reactContext.deactivate();
      localStorage.setItem("isWalletConnected", false);
      localStorage.removeItem("connectedWallet");
      localStorage.removeItem("chainId");
      setConnectedChainId("");
      props.setCurrentAccount(false);
      setSelectChainId(false);
    } catch (ex) {
      alert(ex);
    }
  };

  const mergeAccount2 = async (e) => {
    e.preventDefault();
    console.log("Starting merge procedure...");
    const message = Math.random().toString(36).substr(2, 10);
    const signature = await library?.getSigner().signMessage(message);
    const token = localStorage.getItem("accessToken");
    const update = {
      metamask_address: account,
      signature: signature,
      message: message,
    };

    setLoading(true);
    fetch("https://api.hunter.metashooter.gg/user/profile/", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(update),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.non_field_errors) {
          setLoading(false);
          setMergeError(data.non_field_errors);
          setIsItsuccess(false);
          console.log("Failed: ", data.non_field_errors);
        } else {
          setLoading(false);
          console.log("Successfully merged with:", data.metamask_address);
          localStorage.setItem("metamask", data.metamask_address);
          localStorage.setItem("isWalletConnected", true);
          setMergeError("Successfully merged with your wallet.");
          setIsItsuccess(true);
          window.location.reload();
        }
      });
  };

  useEffect(() => {
    const connectWalletOnPageLoad = async () => {
      if (localStorage?.getItem("isWalletConnected") === "true") {
        const connectedWallet = localStorage?.getItem("connectedWallet");
        if (connectedWallet === "injected") {
          try {
            await web3reactContext.activate(injected);
          } catch (ex) {
            console.log(ex);
          }
        } else if (connectedWallet === "walletconnect") {
          try {
            await web3reactContext.activate(walletconnect);
          } catch (ex) {
            console.log(ex);
          }
        } else if (connectedWallet === "walletlink") {
          try {
            await web3reactContext.activate(walletlink);
          } catch (ex) {
            console.log(ex);
          }
        } else if (connectedWallet === "binance") {
          try {
            await web3reactContext.activate(binance);
          } catch (ex) {
            console.log(ex);
          }
        }
      }
    };
    connectWalletOnPageLoad();
  }, []);

  return (
    <>
      <PopUp visible={visible} onClose={() => setVisible(false)}>
        <div className={cn(styles.popContainer)}>
          <h4>Connect your wallet</h4>
          <div className={cn(styles.selectChain)}>
            <div className={cn(styles.radio)}>
              <label onClick={connectMetaMaskAdvanced}>
                <span className="checkmark">MetaMask</span>
                <img src="/images/wallets/metamask-wallet.svg" alt="Main" />
                <input type="radio" name="chain" id="option1" autoComplete="off" />
              </label>
              {selectChainId ? (
                <div className={styles.selectChainId}>
                  <span>Select a network: </span>
                  <div className={styles.chainId} onClick={connectMetamaskPolygon}>
                    <img src="/images/chains/matic.png" height="20px" />
                    Polygon
                  </div>
                  <div className={styles.chainId} onClick={connectMetamaskBinance}>
                    <img src="/images/chains/bnb.png" height="20px" />
                    Binance
                  </div>
                  <div className={styles.chainId} onClick={connectMetamaskOkc}>
                    <img src="/images/chains/okex.png" height="20px" />
                    OKEX
                  </div>
                  <div className={styles.chainId} onClick={connectMetamaskEth}>
                    <img src="/images/chains/ethereum.png" height="20px" />
                    ETH
                  </div>
                </div>
              ) : null}
            </div>
            <div className={cn(styles.radio)}>
              <label onClick={connectTrustWalletAdvanced}>
                <span className="checkmark">Trust Wallet</span>
                <img src="/images/wallets/trustwallet-wallet.svg" alt="Main" />
                <input type="radio" name="chain" id="option1" autoComplete="off" />
              </label>
              {selectChainId2 ? (
                <div className={styles.selectChainId}>
                  <span>Select a network: </span>
                  <div className={styles.chainId} onClick={connectMetamaskPolygon}>
                    <img src="/images/chains/matic.png" height="20px" />
                    Polygon
                  </div>
                  <div className={styles.chainId} onClick={connectMetamaskBinance}>
                    <img src="/images/chains/bnb.png" height="20px" />
                    Binance
                  </div>
                  <div className={styles.chainId} onClick={connectMetamaskOkc}>
                    <img src="/images/chains/okex.png" height="20px" />
                    OKEX
                  </div>
                  <div className={styles.chainId} onClick={connectMetamaskEth}>
                    <img src="/images/chains/ethereum.png" height="20px" />
                    ETH
                  </div>
                </div>
              ) : null}
            </div>
            <div className={cn(styles.radio)}>
              <label onClick={connectWalletConnectSimple}>
                <span className="checkmark">WalletConnect</span>
                <img src="/images/wallets/walletconnect-wallet.svg" alt="Main" />
                <input type="radio" name="chain" id="option2" autoComplete="off" />
              </label>
            </div>
            <div className={cn(styles.radio)}>
              <label onClick={connectCoinbaseSimple}>
                <span className="checkmark">Coinbase Wallet</span>
                <img src="/images/wallets/coinbase-wallet.svg" alt="Main" />
                <input type="radio" name="chain" id="option3" autoComplete="off" />
              </label>
            </div>
            <div className={cn(styles.radio)}>
              <label onClick={connectBinanceSimple}>
                <span className="checkmark">Binance</span>
                <img src="/images/wallets/binance-wallet.svg" alt="Main" />
                <input type="radio" name="chain" id="option4" autoComplete="off" />
              </label>
            </div>
            <div className={cn(styles.radio)}>
              <label onClick={connectPortisSimple}>
                <span className="checkmark">Portis</span>
                <img src="/images/wallets/portis-wallet.svg" height="60px" alt="Main" />
                <input type="radio" name="chain" id="option5" autoComplete="off" />
              </label>
            </div>
            <div className={cn(styles.radio)}>
              <label onClick={connectMetamaskSimple}>
                <span className="checkmark">Other Wallets</span>
                <img src="/images/wallets/other-wallet.svg" height="60px" alt="Main" />
                <input type="radio" name="chain" id="option5" autoComplete="off" />
              </label>
            </div>
          </div>
        </div>
      </PopUp>

      <PopUp visible={visible3} onClose={() => {}}>
        <div className={cn(styles.popContainer)}>
          <h4>Merge your wallet</h4>
          <div className={cn(styles.selectChain)}>
            <p>
              In-game system can only detect your gaming NFTs if your wallet is merged to the dashboard, this will allow
              you to use NFT marketplace and NFT gaming equipment in the MetaShooter game.
            </p>
            {loading ? (
              <ReactLoading width={30} className={styles.loaderIcon} />
            ) : (
              <p className={isItsuccess ? styles.successMessage2 : styles.errorMessage2}>{mergeError}</p>
            )}
            <div className={cn(styles.radio)}>
              <label className={styles.mergeButtonContainer} onClick={mergeAccount2}>
                <span className={styles.mergeButton}>Sign up (merge) your wallet</span>
                <input type="radio" name="chain" id="option2" autoComplete="off" />
              </label>
            </div>
          </div>
        </div>
      </PopUp>

      <PopUp visible={visible2} onClose={() => setVisible2(false)}>
        <div className={cn(styles.popContainer)}>
          <h4>Your wallet</h4>
          <div className={cn(styles.innerConnected)}>
            <p>Connected with {connectedChainId}</p>
            <button onClick={() => disconnect()}>Disconnect</button>
            <button
              onClick={() => {
                setVisible(true);
                disconnectNoReload();
              }}
            >
              Change
            </button>
          </div>
          <div className={cn(styles.innerWalletId)}>
            <p>{account ? account : "Wallet is not connected"}</p>
          </div>
          <div className={cn(styles.innerViewCopy)}>
            <a target="_blank" href={`${getChainExplorer(chainId)}/address/${account}`}>
              View on explorer
            </a>
            <span onClick={setCopied}>{isCopied ? "Copied" : "Copy address"}</span>
          </div>
        </div>
      </PopUp>
      <NavLink
        onClick={active ? () => setVisible2(true) : () => setVisible(true)}
        className={cn("button-white", styles.buttonConnect)}
        to={loggedIn ? "#" : "/"}
      >
        {web3reactContext.active && loggedIn ? (
          <span className={cn(styles.walletKey)}>
            {isMobile
              ? `${account.substr(0, 3)}...${account.substr(-1, 1)}`
              : `${account.substr(0, 8)}...${account.substr(-6, 6)}`}
          </span>
        ) : !active && loggedIn ? (
          <span>Connect wallet</span>
        ) : (
          <span>Log In</span>
        )}
      </NavLink>
    </>
  );
};

export default Connect;
