import {useState, useEffect} from 'react';
import {Contract as MultiContract} from 'ethers-multicall';
import {useStakeContract, useStakeContractV3, useStakeMultiContract, useStakeMultiContractV3} from "./useContract";
import {Contract } from "@ethersproject/contracts";
import {useStakeCount} from "./useStakeCount";
import {STAKE_ADDRESS, STAKE_HUNTERS_V3_ADDRESS, STAKE_V2_ADDRESS, STAKE_V3_ADDRESS} from "../constants/addresses";
import {useWeb3React} from "@web3-react/core";
import {getStakeVersion} from "../functions/stake";
import {getCallProvider} from "../functions/contract";

let cache = new Map<string, Map<string, StakeRecord[] | undefined >>();

export interface StakeRecord {
    lockTimestamp: number;
    unstaked: boolean;
    amount: number;
    lockDays: number;
    contract: MultiContract;
    version: string;
    shareBonus?: number;
    shareLongBonus?: number;
}

export enum StakeToken { 
    MHUNT = "MHUNT", 
    HUNTERS = "HUNTERS", 
} 

export function useStakeRecords(account?: null | string, stakeToken?: StakeToken ): StakeRecord[] {
    const { chainId } = useWeb3React();

    const stakeContract = useStakeContract(STAKE_ADDRESS);
    const stakeV2Contract = useStakeContract(STAKE_V2_ADDRESS);
    const stakeV3Contract = useStakeContractV3(STAKE_V3_ADDRESS);
    const stakeHuntersV3Contract = useStakeContractV3(STAKE_HUNTERS_V3_ADDRESS);

    const stakeCount = useStakeCount(stakeContract, account)
    const stakeV2Count = useStakeCount(stakeV2Contract, account)
    const stakeV3Count = useStakeCount(stakeV3Contract, account)
    const stakeHuntersV3Count = useStakeCount(stakeHuntersV3Contract, account)

    const stakeInfo: StakeRecord[] = [];
    const [stakes, setStakes] = useState(stakeInfo);
    const [stakesV2, setStakesV2] = useState(stakeInfo);
    const [stakesV3, setStakesV3] = useState(stakeInfo);
    const [stakesHuntersV3, setStakesHunters] = useState(stakeInfo);
    const [loading, setLoading] = useState('');

    const stakeContractMulti = useStakeMultiContract(STAKE_ADDRESS);
    const stakeV2ContractMulti = useStakeMultiContract(STAKE_V2_ADDRESS);
    const stakeV3ContractMulti = useStakeMultiContractV3(STAKE_V3_ADDRESS);
    const stakeHuntersV3ContractMulti = useStakeMultiContractV3(STAKE_HUNTERS_V3_ADDRESS);

    const callProvider = getCallProvider(chainId, window);

    const getStakeInfo = async (stakeCount: number, setStakes: any, stakeContractMulti: MultiContract | null,
                                stakeContract: Contract | null, account?: null | string) => {
        if (stakeCount > 0 && account && stakeContractMulti && callProvider && stakeContract) {
            let version = getStakeVersion(stakeContract, chainId);
            let accountCacheKey = account+stakeToken;
            let accountCache = cache.get(accountCacheKey);

            if (accountCache && accountCache.has(version)) {
                setStakes(accountCache.get(version));
                setLoading('');
            } else {
                let calls = [];
                setLoading('Loading...');
                for (let i = 0; i < stakeCount; i++) {
                    calls.push(stakeContractMulti.stakers(account, i))
                }
                try {
                    const callResults: any = await callProvider.all(calls);
                    const results = callResults.map((value: any, index: any) => {
                        return {...value, contract: stakeContract, id: index, version: version};
                    })

                    setStakes(results);
                    setLoading('');

                    let cacheTemp = new Map<string, StakeRecord[] | undefined >();
                    cacheTemp.set("1", cache.get(accountCacheKey)?.get("1"))
                    cacheTemp.set("2", cache.get(accountCacheKey)?.get("2"))
                    cacheTemp.set("3", cache.get(accountCacheKey)?.get("3"))
                    cacheTemp.set(version, results)
                    cache.set(accountCacheKey, cacheTemp)
                } catch (error) {
                    console.error("Error getting stake info", error)
                }
            }
        }
    }

    useEffect(() => {
        if (stakeToken === StakeToken.HUNTERS){
            getStakeInfo(stakeHuntersV3Count, setStakesHunters, stakeHuntersV3ContractMulti, stakeHuntersV3Contract, account)
        } else if (stakeToken === StakeToken.MHUNT) {
            getStakeInfo(stakeV3Count, setStakesV3, stakeV3ContractMulti, stakeV3Contract, account)
            getStakeInfo(stakeV2Count, setStakesV2, stakeV2ContractMulti, stakeV2Contract, account)
            getStakeInfo(stakeCount, setStakes, stakeContractMulti, stakeContract, account)
        }
    }, [stakeCount, stakeV2Count, stakeV3Count, stakeHuntersV3Count, account, stakeToken]);

    if (stakeToken === StakeToken.HUNTERS){
        return stakesHuntersV3;
    } else if (stakeToken === StakeToken.MHUNT) {
        return [...stakes, ...stakesV2, ...stakesV3];
    }
    return [];
}