import {commonBoxWinners} from "../constants/commonBoxWinners";
import {commonMysteryBoxItem} from "../constants/items";
import {NFT} from "../constants/interfaces";

export const addWinnerCommonMysteryBoxes = (wallet: NFT[]): NFT[] => {
    const userEmail = localStorage.getItem("email") ?? "";
    for (let i = 0; i < commonBoxWinners.length; i++) {
        if (commonBoxWinners[i].toLowerCase() == userEmail.toLowerCase()){
            const newNft = { ...commonMysteryBoxItem } as NFT;
            newNft.isBox = true;
            newNft.isVirtual = true;
            wallet.push( { ...newNft } as NFT);
        }
    }
    return wallet;
}


export const getStorageNfts = (chainId: number | null) => {
    return localStorage.getItem(`owned_nfts_${chainId}`)
}

export const setStorageNfts = (chainId: number | null, value: string) => {
    return localStorage.setItem(`owned_nfts_${chainId}`, value)
}