import { DEFAULT_PAGE_SIZE } from "../constants/common";

export function clearLocalStorage(){
    const fields = ['accessToken', 'refreshToken', 'metamask', 'items', 'username', 'email',
        'telegram', 'avatar', 'joined', 'guild', 'twitter', 'discord', 'isWalletConnected',
        'connectedWallet', 'daily_login_streak'];

    for(const f of fields) {
        localStorage.removeItem(f);
    }
}

export function isWalletMerged(){
    const metamask = localStorage.getItem("metamask") ?? ""
    return metamask && metamask.length === 42
}

export function getPagesArray(resultCount, resultsPerPage){
    if (resultsPerPage === undefined) {
        resultsPerPage = DEFAULT_PAGE_SIZE;
    }
    var pages = []
    for (var a=1; a<=Math.ceil(resultCount / resultsPerPage); a++)
    pages.push(a)
    return pages;
}

export function truncateAddress(address){
    if (address.length == 42){
        return `${address.substr(0, 8)}...${address.substr(-6, 6)}`
    }
    return ""
}

export function timestampToLocaleString(timestamp){
    const date = new Date(timestamp * 1000);
    return date.toLocaleString();
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}