import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import "react-dates/lib/css/_datepicker.css";
import "./styles/app.sass";
import Page from "./components/Page";
import Authentification from "./screens/Authentification";
import Marketplace from "./screens/Marketplace";
import Crafting from "./screens/Crafting";
import Overview from "./screens/Overview";
import NFT from "./screens/NFT";
import IdoClaim from "./screens/IdoClaim";
import Settings from "./screens/Settings";
import Scores from "./screens/Scores";
import Swap from "./screens/Swap";
import GameWallet from "./screens/GameWallet";
import DownloadGame from "./screens/DownloadGame";
import Staking from "./screens/Staking/index";
import StakingHunters from "./screens/StakingHunters/index";
import NotFound from "./screens/NotFound";
import YourStakes from "./screens/YourStakes";
import MyProfile from "./screens/MyProfile";
import ReferralProgram from "./screens/ReferralProgram";
import AccountConfirmEmail from "./screens/AccountConfirmEmail";
import PasswordResetConfirm from "./screens/PasswordResetConfirm";
import GettingStarted from "./screens/GettingStarted";
import DailyQuests from "./screens/DailyQuests";
import PurchaseTokens from "./screens/PurchaseTokens";
import Product from "./screens/Marketplace/Product";
import Recipe from "./screens/Crafting/Recipe";
import MysteryBoxCheck from "./screens/MysteryBoxCheck";
import {Web3ReactProvider} from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers'
import useRefreshToken from './hooks/useRefreshToken'
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { StakeToken } from "./hooks/useStakeRecords";
import PvpBattle from "./screens/PvpBattles/PvpBattle";
import { RecoilRoot } from "recoil";

LogRocket.init('txtjml/metashooter');
setupLogRocketReact(LogRocket);

function getLibrary(provider) {
    return new Web3Provider(provider)
}



function App({Component, pageProps}) {
    useRefreshToken();
    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <RecoilRoot>
                <Router {...pageProps}>
                    <Switch>
                        <Route exact path="/" render={() => {
                            return (<Page>
                                        <Redirect to="/my-stakes"/>
                                    </Page>)
                        }}/>
                        <Route exact path="/my-stakes" render={() => (
                                <Page withMainLayout={false}>
                                    <YourStakes stakeToken={StakeToken.MHUNT} />
                                </Page>
                        )}
                        />                    
                        <Route exact path="/my-hunters-stakes" render={() => (
                            <Page notAuthorized withMainLayout={false}>
                                <YourStakes stakeToken={StakeToken.HUNTERS} />
                            </Page>
                        )}
                        />
                        <Route path="*" render={() => (
                            <Page>
                                <NotFound/>
                            </Page>
                        )}
                        />
                    </Switch>
                </Router>
            </RecoilRoot>
        </Web3ReactProvider>
    );
}

export default App;
