import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Link, NavLink } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Notifications.module.sass";
import { useWeb3React } from "@web3-react/core"
import { leaderBoardHistory } from "./steam_codes"

const NotificationsList = [
];



const Dropdown = ({ className, value, setValue, options, empty }) => {
  const [visible, setVisible] = useState(false);
  const { account } = useWeb3React();
  const [metamask, setMetamask] = useState(localStorage.getItem('metamask'));
  const email = localStorage.getItem('email');
  //const email = 'nonexisting@gmail.com';


  const handleClick = (value) => {
    setValue(value);
    setVisible(false);
  };
  useEffect(() => {
    const getUserData = () => {
        const token = localStorage.getItem('accessToken');
        fetch('https://api.hunter.metashooter.gg/user/profile/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setMetamask(data.metamask_address);
        })
        .catch((error) => {
            console.error(error);
        });
    }
    getUserData();
  }, []);
  
  const yourCode = leaderBoardHistory.filter(e => e.email === email).length > 0 ? leaderBoardHistory.filter(e => e.email === email)[0].code : null;

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(styles.dropdown, { [styles.empty]: empty }, className, {
          [styles.active]: visible,
        })}
      >
        <div className={styles.head} onClick={() => setVisible(!visible)}>
          <div className={styles.selection}>
            <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.4093 8.71626C17.4565 8.57494 17.5035 8.40997 17.5271 8.26863C17.6684 7.70321 17.7391 7.11428 17.7391 6.52535C17.7391 5.32397 17.48 4.19313 16.9382 3.10958C16.7969 2.82695 16.4434 2.68564 16.1608 2.85045C15.8782 2.99176 15.7604 3.34523 15.9017 3.62784C16.3493 4.52305 16.5613 5.51246 16.5613 6.52534C16.5613 7.02013 16.5141 7.51475 16.3963 8.00949C16.3728 8.15081 16.3256 8.26863 16.3021 8.38645C16.208 8.69274 16.3963 9.02253 16.7026 9.11668C16.7497 9.14018 16.8204 9.14018 16.8676 9.14018C17.103 9.11668 17.315 8.9517 17.4093 8.71623L17.4093 8.71626Z" fill="white"/>
              <path d="M2.56784 8.7163C2.6385 8.97544 2.87413 9.14024 3.13325 9.14024C3.18041 9.14024 3.25107 9.14024 3.29823 9.11675C3.60453 9.02259 3.79302 8.6928 3.69869 8.38651C3.65153 8.26869 3.62803 8.12737 3.60453 8.00956C3.48671 7.51477 3.43955 7.02015 3.43955 6.52541C3.43955 5.51253 3.67519 4.54664 4.09915 3.6279C4.24047 3.34527 4.12265 2.99181 3.84001 2.85051C3.55738 2.70921 3.20393 2.82701 3.06262 3.10965C2.54436 4.16969 2.26172 5.32408 2.26172 6.52542C2.26172 7.11435 2.33238 7.70328 2.4737 8.2687C2.49719 8.41002 2.52069 8.55133 2.56785 8.71633L2.56784 8.7163Z" fill="white"/>
              <path d="M9.98881 16.231C10.9075 16.231 11.6378 15.5008 11.6378 14.5821L8.33984 14.5819C8.33984 15.5006 9.07008 16.231 9.98881 16.231V16.231Z" fill="white"/>
              <path d="M4.64115 6.28983V11.0484L3.4868 13.0272C3.36898 13.2157 3.36898 13.4276 3.4868 13.6161C3.58096 13.8046 3.7931 13.9224 4.00506 13.9224H16.0193C16.2313 13.9224 16.4197 13.8046 16.5375 13.6161C16.6317 13.4276 16.6317 13.2157 16.5375 13.0272L15.3832 11.0484V6.28983C15.3832 3.53363 13.2866 1.27213 10.6246 0.965886V0.588932C10.6246 0.259139 10.3655 0 10.0357 0C9.70589 0 9.44675 0.259139 9.44675 0.588932V0.965886C6.71407 1.27202 4.64107 3.53342 4.64107 6.28983H4.64115ZM11.0016 3.62782C11.1901 3.36868 11.5434 3.29802 11.8262 3.46284C12.7214 4.07528 13.2632 5.08833 13.2632 6.17184C13.2632 6.50163 13.0041 6.76077 12.6743 6.76077C12.3445 6.76077 12.0853 6.50163 12.0853 6.17184C12.0853 5.46508 11.732 4.82899 11.1666 4.42856C10.8838 4.26391 10.8131 3.88695 11.0016 3.62782L11.0016 3.62782Z" fill="white"/>
              <path d="M1.1542 6.50191C1.1542 4.87642 1.60183 3.29812 2.44989 1.90824C2.61488 1.62561 2.52055 1.27215 2.26142 1.10734C1.97878 0.942356 1.62533 1.03668 1.46051 1.29582C0.494619 2.85064 0 4.66442 0 6.50198C0 7.63268 0.188479 8.73988 0.565417 9.79991C0.659575 10.0356 0.871712 10.2004 1.13083 10.2004C1.20149 10.2004 1.27215 10.2004 1.31931 10.1769C1.62561 10.059 1.79042 9.72924 1.67261 9.42295C1.31915 8.48057 1.15418 7.49117 1.15418 6.5018L1.1542 6.50191Z" fill="white"/>
              <path d="M17.8342 1.27208C17.5515 1.43706 17.4572 1.79034 17.6222 2.07299C18.3996 3.41584 18.8236 4.94698 18.8236 6.50176C18.8236 7.49116 18.6586 8.48053 18.3288 9.4229C18.211 9.7292 18.3759 10.059 18.6821 10.1768C18.7527 10.2003 18.8234 10.2003 18.8706 10.2003C19.1062 10.2003 19.3417 10.059 19.436 9.79986C19.8129 8.73983 20.0014 7.6326 20.0014 6.50193C20.0014 4.73513 19.5303 2.99185 18.6351 1.48423C18.4702 1.20143 18.117 1.10711 17.8342 1.27209L17.8342 1.27208Z" fill="white"/>
            </svg>
            <div className={NotificationsList.length > 0 ? styles.notificationsCount : null}>{NotificationsList.length > 0 ? NotificationsList.length : null}</div>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.innerHeader}><h5>Notifications</h5> <span></span></div>
          {
            NotificationsList.map((x, index) => (
              <div key={index} className={styles.notificationBody}>{x.title}<NavLink
              className={cn("link-green", styles.link)}
              to={x.link}
            >Click here
            </NavLink></div>
            ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
