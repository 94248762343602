import {useState, useEffect} from 'react';
import {Contract} from "@ethersproject/contracts";


export function useStakerRewardPending( contract: Contract | null, account?: null | string): string {
    const [stakerRewardPending, setStakerRewardPending] = useState("0");

    const getStakerReward = async (contract: Contract | null, account?: null | string) => {
        if (contract && account) {
            contract.getStakerReward(account)
                .then((value: any) => {
                    setStakerRewardPending(value.toString());
                })
                .catch((e: any) => {
                    console.log("Error getting useStakerRewardPending ", e)
                })
        }
    }

    useEffect(() => {
        getStakerReward(contract, account)
    }, [contract, account]);

    return stakerRewardPending;
}