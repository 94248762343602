import { withRouter, useLocation } from "react-router-dom";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import styles from "./Page.module.sass";
import Header from "../Header";
import Footer from "../Footer";
import React, { useState, useEffect } from "react";
import Main from "../../components/Main";
import Menu from "../../components/Menu";
import FadeIn from 'react-fade-in';
import Warning from "../Warning";
import { useWeb3React } from "@web3-react/core";
import { isWalletMerged } from "../../functions/utils";

const Page = ({
  separatorHeader,
  children,
  wide,
  withMainLayout
}) => {
  const { pathname, search }= useLocation();
  const {active} = useWeb3React();
  const merged = isWalletMerged();

  const ref  = new URLSearchParams(search).get('ref')
  if (ref && ref.length === 8){
    localStorage.setItem("referral_code", ref)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
    setMenuOpened(localStorage.getItem('menu') !== null ? localStorage.getItem('menu') : []);
  }, [pathname]);

  const [menuOpened, setMenuOpened] = useState([]);

  const handleMenuOpenClose = (title, isSubmenu) => {
    if(menuOpened.includes(title)) {
      setMenuOpened([]);
      localStorage.setItem('menu', title);
    } else {
      setMenuOpened(title);
      localStorage.setItem('menu', title);
    }
    console.log(menuOpened, isSubmenu);
  }

  console.log("Initial meniu opemened: ", menuOpened)

  if (withMainLayout){
    return (
        <>
          <div className={styles.page}>
            <Header
              path={pathname}
              separatorHeader={separatorHeader}
              wide={wide}
            />
            <div className={styles.inner}>
              <Main title={'Game download'}/>
                <div className={styles.MetaContainer}>
                  <Menu menuOpened={menuOpened} handleMenuOpenClose={handleMenuOpenClose} />
                  <div style={{width: "100%"}}>
                    <FadeIn>
                      {active && merged ? children : <Warning text={'using Metashooter dashboard.'} />}
                    </FadeIn>
                  </div>
                </div>
            </div>
            <Footer />
          </div>
        </>
    );
  } else {
    return (
        <>
          <div className={styles.page}>
            <Header
              path={pathname}
              separatorHeader={separatorHeader}
              wide={wide}
            />
            <div className={styles.inner} style={{"margin-top":"110px"}}>
              {children}
            </div>
          </div>
        </>
      );
  }
};

export default withRouter(Page);
