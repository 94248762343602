import React, { useState, useEffect } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import { useWeb3React } from "@web3-react/core"
import Icon from "../Icon";


const Dropdown = ({ className, value, setValue, options, empty }) => {
  const arrow = <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1 1L9 9L17 1" stroke="#848688" strokeWidth="2" strokeLinecap="round"/>
  </svg>  
  const [visible, setVisible] = useState(false);
  const { chainId } = useWeb3React();
  const handleClick = (value) => {
    setValue(value);
    setVisible(false);
  };
  
  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(styles.dropdown, { [styles.empty]: empty }, className, {
          [styles.active]: visible,
        })}
      >
        <div className={styles.head} onClick={() => setVisible(!visible)}>
          <div className={styles.selection}>
            <img src={chainId === 137 ? "/images/chains/matic.png" : chainId === 66 ? "/images/chains/okex.png" : chainId === 1 ? "/images/chains/ethereum.png" : "/images/chains/bnb.png" } height="20px" />
          </div>
          <div className={styles.arrow}>
            {arrow}
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.selectionHeader}>
            <h4>Select network</h4>
          </div>
          <div className={styles.selectionBody}>
            {options.map((x, index) => (
              <div key={index} className={styles.sectionBodyRow}>
                <img
                  className={cn(styles.option, {
                    [styles.selectioned]: x === value,
                  })}
                  height="40px"
                  src={x.img}
                />
                <span>{x.name}</span>
                <button onClick={() => handleClick(x.img, index)} className={x.id === chainId ? styles.buttonSelected : styles.buttonSwitch}>{x.id === chainId ? "Selected" : "Switch"}</button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
