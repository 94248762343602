import {useState, useEffect} from 'react';
import {useStakeContract} from "./useContract";
import {formatEther} from "@ethersproject/units";
import {Contract} from "@ethersproject/contracts";
import {BigNumber} from "@ethersproject/bignumber";
import {STAKE_ADDRESS, STAKE_HUNTERS_V3_ADDRESS, STAKE_V2_ADDRESS, STAKE_V3_ADDRESS} from "../constants/addresses";
import { StakeToken } from './useStakeRecords';


export function useTotalStaked(account?: null | string, stakeToken?: StakeToken) {
    const [totals, setTotals] = useState({totalStaked: BigNumber.from(0), totalShares: BigNumber.from(0)});
    const [totalsV2, setTotalsV2] = useState({totalStaked: BigNumber.from(0), totalShares: BigNumber.from(0)});
    const [totalsV3, setTotalsV3] = useState({totalStaked: BigNumber.from(0), totalShares: BigNumber.from(0)});
    const [totalsHuntersV3, setTotalsHuntersV3] = useState({totalStaked: BigNumber.from(0), totalShares: BigNumber.from(0)});
    const stakeContract = useStakeContract(STAKE_ADDRESS)
    const stakeV2Contract = useStakeContract(STAKE_V2_ADDRESS)
    const stakeV3Contract = useStakeContract(STAKE_V3_ADDRESS)
    const stakeHuntersV3Contract = useStakeContract(STAKE_HUNTERS_V3_ADDRESS)

    const getTotalStaked = (contract: Contract | null, setTotals: any, account?: null | string) => {
        if (contract && account) {
            try {
                contract.getStakerInfo(account).then((value: any) => {
                    setTotals({totalStaked: value.totalStakeAmount,
                               totalShares: value.totalStakerShares});
                })
            }
            catch (error) {
                console.log("Error getting total staked", account, error)
            }
        }
    }

    useEffect(() => {
        if (stakeToken === StakeToken.HUNTERS){
            getTotalStaked(stakeHuntersV3Contract, setTotalsHuntersV3, account)
        } else if (stakeToken === StakeToken.MHUNT) {
            getTotalStaked(stakeContract, setTotals, account)
            getTotalStaked(stakeV2Contract, setTotalsV2, account)
            getTotalStaked(stakeV3Contract, setTotalsV3, account)
        }
    }, [account, stakeToken]);

    if (stakeToken === StakeToken.HUNTERS){
        return {
            totalStaked: +formatEther(totalsHuntersV3.totalStaked),
            totalShares: +formatEther(totalsHuntersV3.totalShares)
        };
    } else if (stakeToken === StakeToken.MHUNT) {
        return {
            totalStaked: +formatEther(totals.totalStaked.add(totalsV2.totalStaked).add(totalsV3.totalStaked)),
            totalShares: +formatEther(totals.totalShares.add(totalsV2.totalShares).add(totalsV3.totalShares))
        };
    }

    return {
        totalStaked: 0,
        totalShares: 0
    };
}

