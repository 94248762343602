import { ChainId } from "./addresses";


export const MHUNT_HUNTERS_SWAP_RATE = {
  [ChainId.MATIC]: 20000,
  [ChainId.BSC]: 20000,
  [ChainId.OKEX]: 20000,
  [ChainId.BSC_TEST]: 20000,
} 

export const MHUNT_HUNTERS_FEE = {
  [ChainId.MATIC]: 400,
  [ChainId.BSC]: 400,
  [ChainId.OKEX]: 400,
  [ChainId.BSC_TEST]: 400,
}

export const HUNDRED_PERCENT_BP = 10000;

export const MARKETPLACE_FEE_RECIPIENT = {
  [ChainId.BSC]: "0xC0c6EF39Ed0e20Ba44bFD28E2e168644D96f45eb"
}

export const MARKETPLACE_FEE = {
  [ChainId.BSC]: 400
}

export enum AuctionType {
  SIMPLE = "SIMPLE",
  ENGLISH = "ENGLISH",
}

export const MARKETPLACE_ZONE = {
  [ChainId.BSC]: "0xEdc890de816Df2296A65CD27C728D80496B77736"
}

export const marketplaceCategories = [ 
    {
      title: "Lands",
      name: "LAND",
      img: "/images/categories/land.png",
      description: "It will give the opportunity to get the most valuable item in the MetaShooter ecosystem — land. With land, players will be capable of developing businesses throughout the metaverse, such as owning a hunter house, displaying your trophies, providing stamina, storage, and other perks for players, own and monetize hunting towers, breed a variety of NFTs, and more…",
    },
    {
      title: "Vehicles",
      name: "VEHICLE",
      img: "/images/categories/vehicles.png",
      description: "Vehicles are one of the greatest items to have. It helps players to reach thrilling open world locations, find rare animals, bring more equipment and trophies with you, and most importantly, save stamina.",
    },
    {
      title: "Guns",
      name: "GUN",
      img: "/images/categories/guns.png",
      description: "Each gun is unique, some of the guns like bows can be stealthy and not distract your prey. Knives will help you not only defend but also convert your trophies into resources that every hunter needs. Other guns might have better range, demage, mobility, or accuracy. Such features will not only give great value to NFT but also help to hunt and compete effectively.",
    },
    {
      title: "Guns skins",
      name: "SKIN",
      img: "/images/categories/guns-skins.png",
      description: "Helps hunters to be more stealthy and undetected. The hunter can come to animals more closely and hunt down prey easily. It also provides a great and unique look to your owned guns.",
    },
    {
      title: "Passes/keys",
      name: "KEY",
      img: "/images/categories/key.png",
      description: "Season pass will allow you to access MetaShooter hunting season, challenges, missions and more. Alpha invitation key will allow you to enjoy the MetaShooter Alpha version, explore the open world, compete, complete missions, and earn.",
    },
    {
      title: "Hunter’s appearance",
      name: "APPEARANCE",
      img: "/images/categories/appearance.png",
      description: "Same as gun skins, appearance helps hunters to be more stealthy and undetectable. It will also make your character a better hunter and look unique.",
    },
    {
      title: "Other",
      name: "MISC",
      img: "/images/categories/mystery-box.png",
      description: "The MetaShooter Mystery Box contains NFT hunting equipment as well as accessories to aid you on your exciting journey through the wilderness. The Hunting Badge will provide discounts on the MetaShooter NFT equipment marketplace, discounts on hunting season licenses, and early access to the most recent NFTs.",
    },
    {
      title: "Hunting equipment",
      name: "EQUIPMENT",
      img: "/images/categories/hunting-equipment.png",
      description: "Such equipment as baits and callers will help you to attract your potential trophies, binoculars will help you to spot them. Bullets are limited, so you will need them all the time.",
    },
  ];