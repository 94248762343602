import { useState, useEffect } from 'react';
import {useHuntersContract} from "./useContract";
import {BigNumber} from "@ethersproject/bignumber";

export function useBalanceHunters(account?: null | string) {
    const [balance, setBalance] = useState(BigNumber.from(0));
    const tokenContract = useHuntersContract( true)

    const getBalance = (account?: null | string) => {
        if (tokenContract && account) {
            tokenContract.balanceOf(account).then((value: any) => {
                setBalance(BigNumber.from(value));
            }).catch((e: any) => {
                console.log("Error getting hunters balance", e)
            })
        }
    }

  useEffect(() => {
      getBalance(account)
  }, [account]);


  return balance;
}